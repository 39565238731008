// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "base/v1/resource.proto" (package "base.v1", syntax proto3)
// tslint:disable
/**
 * @generated from protobuf enum base.v1.ResourceType
 */
export enum ResourceType {
    /**
     * @generated from protobuf enum value: RESOURCE_TYPE_INVALID = 0;
     */
    INVALID = 0,
    /**
     * @generated from protobuf enum value: RESOURCE_TYPE_ROOM = 1;
     */
    ROOM = 1,
    /**
     * @generated from protobuf enum value: RESOURCE_TYPE_BOARD = 2;
     */
    BOARD = 2
}
