// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "notification/v1/notification.proto" (package "notification.v1", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Board } from "../../whiteboard/board/v1/board";
import { Room } from "../../room/v1/room";
import { User } from "../../base/v1/user";
import { Timestamp } from "../../google/protobuf/timestamp";
import { NotificationType } from "../../base/v1/notifcation";
/**
 * @generated from protobuf message notification.v1.Notification
 */
export interface Notification {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: base.v1.NotificationType notification_type = 2;
     */
    notificationType: NotificationType;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_on = 3;
     */
    createdOn?: Timestamp;
    /**
     * @generated from protobuf field: base.v1.User from_user = 4;
     */
    fromUser?: User;
    /**
     * @generated from protobuf field: room.v1.Room room = 5;
     */
    room?: Room;
    /**
     * @generated from protobuf field: whiteboard.board.v1.Board board = 6;
     */
    board?: Board;
}
// @generated message type with reflection information, may provide speed optimized methods
class Notification$Type extends MessageType<Notification> {
    constructor() {
        super("notification.v1.Notification", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "notification_type", kind: "enum", T: () => ["base.v1.NotificationType", NotificationType, "NOTIFICATION_TYPE_"] },
            { no: 3, name: "created_on", kind: "message", T: () => Timestamp },
            { no: 4, name: "from_user", kind: "message", T: () => User },
            { no: 5, name: "room", kind: "message", T: () => Room },
            { no: 6, name: "board", kind: "message", T: () => Board }
        ]);
    }
    create(value?: PartialMessage<Notification>): Notification {
        const message = { id: "", notificationType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Notification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Notification): Notification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* base.v1.NotificationType notification_type */ 2:
                    message.notificationType = reader.int32();
                    break;
                case /* google.protobuf.Timestamp created_on */ 3:
                    message.createdOn = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdOn);
                    break;
                case /* base.v1.User from_user */ 4:
                    message.fromUser = User.internalBinaryRead(reader, reader.uint32(), options, message.fromUser);
                    break;
                case /* room.v1.Room room */ 5:
                    message.room = Room.internalBinaryRead(reader, reader.uint32(), options, message.room);
                    break;
                case /* whiteboard.board.v1.Board board */ 6:
                    message.board = Board.internalBinaryRead(reader, reader.uint32(), options, message.board);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Notification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* base.v1.NotificationType notification_type = 2; */
        if (message.notificationType !== 0)
            writer.tag(2, WireType.Varint).int32(message.notificationType);
        /* google.protobuf.Timestamp created_on = 3; */
        if (message.createdOn)
            Timestamp.internalBinaryWrite(message.createdOn, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* base.v1.User from_user = 4; */
        if (message.fromUser)
            User.internalBinaryWrite(message.fromUser, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* room.v1.Room room = 5; */
        if (message.room)
            Room.internalBinaryWrite(message.room, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* whiteboard.board.v1.Board board = 6; */
        if (message.board)
            Board.internalBinaryWrite(message.board, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notification.v1.Notification
 */
export const Notification = new Notification$Type();
