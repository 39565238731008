import {
  ListTemplatesRequest,
  ListTemplatesResponse,
  GetTemplateRequest,
  GetTemplateResponse,
  CreateTemplateRequest,
  CreateTemplateResponse,
  DeleteTemplateRequest,
  DeleteTemplateResponse,
} from "./template_api";

//==================================//
//          Client Code             //
//==================================//

interface Rpc {
  request(
    service: string,
    method: string,
    contentType: "application/json" | "application/protobuf",
    data: object | Uint8Array
  ): Promise<object | Uint8Array>;
}

export interface TemplateAPIClient {
  ListTemplates(request: ListTemplatesRequest): Promise<ListTemplatesResponse>;
  GetTemplate(request: GetTemplateRequest): Promise<GetTemplateResponse>;
  CreateTemplate(
    request: CreateTemplateRequest
  ): Promise<CreateTemplateResponse>;
  DeleteTemplate(
    request: DeleteTemplateRequest
  ): Promise<DeleteTemplateResponse>;
}

export class TemplateAPIClientJSON implements TemplateAPIClient {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ListTemplates.bind(this);
    this.GetTemplate.bind(this);
    this.CreateTemplate.bind(this);
    this.DeleteTemplate.bind(this);
  }
  ListTemplates(request: ListTemplatesRequest): Promise<ListTemplatesResponse> {
    const data = ListTemplatesRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "template.v1.TemplateAPI",
      "ListTemplates",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      ListTemplatesResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  GetTemplate(request: GetTemplateRequest): Promise<GetTemplateResponse> {
    const data = GetTemplateRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "template.v1.TemplateAPI",
      "GetTemplate",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      GetTemplateResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  CreateTemplate(
    request: CreateTemplateRequest
  ): Promise<CreateTemplateResponse> {
    const data = CreateTemplateRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "template.v1.TemplateAPI",
      "CreateTemplate",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      CreateTemplateResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }

  DeleteTemplate(
    request: DeleteTemplateRequest
  ): Promise<DeleteTemplateResponse> {
    const data = DeleteTemplateRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "template.v1.TemplateAPI",
      "DeleteTemplate",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      DeleteTemplateResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }
}

export class TemplateAPIClientProtobuf implements TemplateAPIClient {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ListTemplates.bind(this);
    this.GetTemplate.bind(this);
    this.CreateTemplate.bind(this);
    this.DeleteTemplate.bind(this);
  }
  ListTemplates(request: ListTemplatesRequest): Promise<ListTemplatesResponse> {
    const data = ListTemplatesRequest.toBinary(request);
    const promise = this.rpc.request(
      "template.v1.TemplateAPI",
      "ListTemplates",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      ListTemplatesResponse.fromBinary(data as Uint8Array)
    );
  }

  GetTemplate(request: GetTemplateRequest): Promise<GetTemplateResponse> {
    const data = GetTemplateRequest.toBinary(request);
    const promise = this.rpc.request(
      "template.v1.TemplateAPI",
      "GetTemplate",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      GetTemplateResponse.fromBinary(data as Uint8Array)
    );
  }

  CreateTemplate(
    request: CreateTemplateRequest
  ): Promise<CreateTemplateResponse> {
    const data = CreateTemplateRequest.toBinary(request);
    const promise = this.rpc.request(
      "template.v1.TemplateAPI",
      "CreateTemplate",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      CreateTemplateResponse.fromBinary(data as Uint8Array)
    );
  }

  DeleteTemplate(
    request: DeleteTemplateRequest
  ): Promise<DeleteTemplateResponse> {
    const data = DeleteTemplateRequest.toBinary(request);
    const promise = this.rpc.request(
      "template.v1.TemplateAPI",
      "DeleteTemplate",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      DeleteTemplateResponse.fromBinary(data as Uint8Array)
    );
  }
}
