// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "aggregate/v1/aggregate_api.proto" (package "aggregate.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Plan } from "../../plan/v1/plan";
import { ActiveSubscriptionWithPayment } from "../../plan/v1/plan";
import { RoleType } from "../../base/v1/role";
import { Room } from "../../room/v1/room";
import { Subscription } from "../../plan/v1/plan";
import { User } from "../../base/v1/user";
import { Timestamp } from "../../google/protobuf/timestamp";
/**
 * @generated from protobuf message aggregate.v1.GetUserAggregateProfileRequest
 */
export interface GetUserAggregateProfileRequest {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp notification_created_on_after = 1;
     */
    notificationCreatedOnAfter?: Timestamp;
}
/**
 * @generated from protobuf message aggregate.v1.GetUserAggregateProfileResponse
 */
export interface GetUserAggregateProfileResponse {
    /**
     * @generated from protobuf field: base.v1.User user = 1;
     */
    user?: User;
    /**
     * @generated from protobuf field: bool has_active_subscription = 2;
     */
    hasActiveSubscription: boolean;
    /**
     * @generated from protobuf field: plan.v1.Subscription subscription = 3;
     */
    subscription?: Subscription;
    /**
     * @generated from protobuf field: int32 notifications_count = 4;
     */
    notificationsCount: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp last_notification_date = 5;
     */
    lastNotificationDate?: Timestamp;
}
/**
 * @generated from protobuf message aggregate.v1.GetBoardAggregateRoomsRequest
 */
export interface GetBoardAggregateRoomsRequest {
    /**
     * @generated from protobuf field: string board_id = 1;
     */
    boardId: string;
}
/**
 * @generated from protobuf message aggregate.v1.GetBoardAggregateRoomsResponse
 */
export interface GetBoardAggregateRoomsResponse {
    /**
     * @generated from protobuf field: repeated room.v1.Room rooms = 1;
     */
    rooms: Room[];
    /**
     * @generated from protobuf field: repeated string room_ids = 2;
     */
    roomIds: string[];
}
/**
 * @generated from protobuf message aggregate.v1.ListAllUsersWithSubscriptionRequest
 */
export interface ListAllUsersWithSubscriptionRequest {
    /**
     * @generated from protobuf field: repeated base.v1.RoleType roles = 1;
     */
    roles: RoleType[];
}
/**
 * @generated from protobuf message aggregate.v1.UserWithSubscription
 */
export interface UserWithSubscription {
    /**
     * @generated from protobuf field: base.v1.User user = 1;
     */
    user?: User;
    /**
     * @generated from protobuf field: plan.v1.ActiveSubscriptionWithPayment active_subscription = 2;
     */
    activeSubscription?: ActiveSubscriptionWithPayment;
}
/**
 * @generated from protobuf message aggregate.v1.ListAllUsersWithSubscriptionResponse
 */
export interface ListAllUsersWithSubscriptionResponse {
    /**
     * @generated from protobuf field: repeated aggregate.v1.UserWithSubscription users = 1;
     */
    users: UserWithSubscription[];
}
/**
 * @generated from protobuf message aggregate.v1.ListAllUsersAndPlansRequest
 */
export interface ListAllUsersAndPlansRequest {
    /**
     * @generated from protobuf field: repeated base.v1.RoleType roles = 1;
     */
    roles: RoleType[];
}
/**
 * @generated from protobuf message aggregate.v1.ListAllUsersAndPlansResponse
 */
export interface ListAllUsersAndPlansResponse {
    /**
     * @generated from protobuf field: repeated base.v1.User users = 1;
     */
    users: User[];
    /**
     * @generated from protobuf field: repeated plan.v1.Plan plans = 2;
     */
    plans: Plan[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetUserAggregateProfileRequest$Type extends MessageType<GetUserAggregateProfileRequest> {
    constructor() {
        super("aggregate.v1.GetUserAggregateProfileRequest", [
            { no: 1, name: "notification_created_on_after", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetUserAggregateProfileRequest>): GetUserAggregateProfileRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetUserAggregateProfileRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserAggregateProfileRequest): GetUserAggregateProfileRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp notification_created_on_after */ 1:
                    message.notificationCreatedOnAfter = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.notificationCreatedOnAfter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserAggregateProfileRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp notification_created_on_after = 1; */
        if (message.notificationCreatedOnAfter)
            Timestamp.internalBinaryWrite(message.notificationCreatedOnAfter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message aggregate.v1.GetUserAggregateProfileRequest
 */
export const GetUserAggregateProfileRequest = new GetUserAggregateProfileRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserAggregateProfileResponse$Type extends MessageType<GetUserAggregateProfileResponse> {
    constructor() {
        super("aggregate.v1.GetUserAggregateProfileResponse", [
            { no: 1, name: "user", kind: "message", T: () => User },
            { no: 2, name: "has_active_subscription", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "subscription", kind: "message", T: () => Subscription },
            { no: 4, name: "notifications_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "last_notification_date", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetUserAggregateProfileResponse>): GetUserAggregateProfileResponse {
        const message = { hasActiveSubscription: false, notificationsCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetUserAggregateProfileResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserAggregateProfileResponse): GetUserAggregateProfileResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* bool has_active_subscription */ 2:
                    message.hasActiveSubscription = reader.bool();
                    break;
                case /* plan.v1.Subscription subscription */ 3:
                    message.subscription = Subscription.internalBinaryRead(reader, reader.uint32(), options, message.subscription);
                    break;
                case /* int32 notifications_count */ 4:
                    message.notificationsCount = reader.int32();
                    break;
                case /* google.protobuf.Timestamp last_notification_date */ 5:
                    message.lastNotificationDate = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.lastNotificationDate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserAggregateProfileResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool has_active_subscription = 2; */
        if (message.hasActiveSubscription !== false)
            writer.tag(2, WireType.Varint).bool(message.hasActiveSubscription);
        /* plan.v1.Subscription subscription = 3; */
        if (message.subscription)
            Subscription.internalBinaryWrite(message.subscription, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int32 notifications_count = 4; */
        if (message.notificationsCount !== 0)
            writer.tag(4, WireType.Varint).int32(message.notificationsCount);
        /* google.protobuf.Timestamp last_notification_date = 5; */
        if (message.lastNotificationDate)
            Timestamp.internalBinaryWrite(message.lastNotificationDate, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message aggregate.v1.GetUserAggregateProfileResponse
 */
export const GetUserAggregateProfileResponse = new GetUserAggregateProfileResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBoardAggregateRoomsRequest$Type extends MessageType<GetBoardAggregateRoomsRequest> {
    constructor() {
        super("aggregate.v1.GetBoardAggregateRoomsRequest", [
            { no: 1, name: "board_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetBoardAggregateRoomsRequest>): GetBoardAggregateRoomsRequest {
        const message = { boardId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBoardAggregateRoomsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBoardAggregateRoomsRequest): GetBoardAggregateRoomsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string board_id */ 1:
                    message.boardId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBoardAggregateRoomsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string board_id = 1; */
        if (message.boardId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.boardId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message aggregate.v1.GetBoardAggregateRoomsRequest
 */
export const GetBoardAggregateRoomsRequest = new GetBoardAggregateRoomsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBoardAggregateRoomsResponse$Type extends MessageType<GetBoardAggregateRoomsResponse> {
    constructor() {
        super("aggregate.v1.GetBoardAggregateRoomsResponse", [
            { no: 1, name: "rooms", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Room },
            { no: 2, name: "room_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetBoardAggregateRoomsResponse>): GetBoardAggregateRoomsResponse {
        const message = { rooms: [], roomIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBoardAggregateRoomsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBoardAggregateRoomsResponse): GetBoardAggregateRoomsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated room.v1.Room rooms */ 1:
                    message.rooms.push(Room.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string room_ids */ 2:
                    message.roomIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBoardAggregateRoomsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated room.v1.Room rooms = 1; */
        for (let i = 0; i < message.rooms.length; i++)
            Room.internalBinaryWrite(message.rooms[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string room_ids = 2; */
        for (let i = 0; i < message.roomIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.roomIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message aggregate.v1.GetBoardAggregateRoomsResponse
 */
export const GetBoardAggregateRoomsResponse = new GetBoardAggregateRoomsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAllUsersWithSubscriptionRequest$Type extends MessageType<ListAllUsersWithSubscriptionRequest> {
    constructor() {
        super("aggregate.v1.ListAllUsersWithSubscriptionRequest", [
            { no: 1, name: "roles", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["base.v1.RoleType", RoleType, "ROLE_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<ListAllUsersWithSubscriptionRequest>): ListAllUsersWithSubscriptionRequest {
        const message = { roles: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListAllUsersWithSubscriptionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAllUsersWithSubscriptionRequest): ListAllUsersWithSubscriptionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated base.v1.RoleType roles */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.roles.push(reader.int32());
                    else
                        message.roles.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAllUsersWithSubscriptionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated base.v1.RoleType roles = 1; */
        if (message.roles.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.roles.length; i++)
                writer.int32(message.roles[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message aggregate.v1.ListAllUsersWithSubscriptionRequest
 */
export const ListAllUsersWithSubscriptionRequest = new ListAllUsersWithSubscriptionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserWithSubscription$Type extends MessageType<UserWithSubscription> {
    constructor() {
        super("aggregate.v1.UserWithSubscription", [
            { no: 1, name: "user", kind: "message", T: () => User },
            { no: 2, name: "active_subscription", kind: "message", T: () => ActiveSubscriptionWithPayment }
        ]);
    }
    create(value?: PartialMessage<UserWithSubscription>): UserWithSubscription {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserWithSubscription>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserWithSubscription): UserWithSubscription {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* plan.v1.ActiveSubscriptionWithPayment active_subscription */ 2:
                    message.activeSubscription = ActiveSubscriptionWithPayment.internalBinaryRead(reader, reader.uint32(), options, message.activeSubscription);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserWithSubscription, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* plan.v1.ActiveSubscriptionWithPayment active_subscription = 2; */
        if (message.activeSubscription)
            ActiveSubscriptionWithPayment.internalBinaryWrite(message.activeSubscription, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message aggregate.v1.UserWithSubscription
 */
export const UserWithSubscription = new UserWithSubscription$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAllUsersWithSubscriptionResponse$Type extends MessageType<ListAllUsersWithSubscriptionResponse> {
    constructor() {
        super("aggregate.v1.ListAllUsersWithSubscriptionResponse", [
            { no: 1, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserWithSubscription }
        ]);
    }
    create(value?: PartialMessage<ListAllUsersWithSubscriptionResponse>): ListAllUsersWithSubscriptionResponse {
        const message = { users: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListAllUsersWithSubscriptionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAllUsersWithSubscriptionResponse): ListAllUsersWithSubscriptionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated aggregate.v1.UserWithSubscription users */ 1:
                    message.users.push(UserWithSubscription.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAllUsersWithSubscriptionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated aggregate.v1.UserWithSubscription users = 1; */
        for (let i = 0; i < message.users.length; i++)
            UserWithSubscription.internalBinaryWrite(message.users[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message aggregate.v1.ListAllUsersWithSubscriptionResponse
 */
export const ListAllUsersWithSubscriptionResponse = new ListAllUsersWithSubscriptionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAllUsersAndPlansRequest$Type extends MessageType<ListAllUsersAndPlansRequest> {
    constructor() {
        super("aggregate.v1.ListAllUsersAndPlansRequest", [
            { no: 1, name: "roles", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["base.v1.RoleType", RoleType, "ROLE_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<ListAllUsersAndPlansRequest>): ListAllUsersAndPlansRequest {
        const message = { roles: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListAllUsersAndPlansRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAllUsersAndPlansRequest): ListAllUsersAndPlansRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated base.v1.RoleType roles */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.roles.push(reader.int32());
                    else
                        message.roles.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAllUsersAndPlansRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated base.v1.RoleType roles = 1; */
        if (message.roles.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.roles.length; i++)
                writer.int32(message.roles[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message aggregate.v1.ListAllUsersAndPlansRequest
 */
export const ListAllUsersAndPlansRequest = new ListAllUsersAndPlansRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAllUsersAndPlansResponse$Type extends MessageType<ListAllUsersAndPlansResponse> {
    constructor() {
        super("aggregate.v1.ListAllUsersAndPlansResponse", [
            { no: 1, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User },
            { no: 2, name: "plans", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Plan }
        ]);
    }
    create(value?: PartialMessage<ListAllUsersAndPlansResponse>): ListAllUsersAndPlansResponse {
        const message = { users: [], plans: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListAllUsersAndPlansResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAllUsersAndPlansResponse): ListAllUsersAndPlansResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated base.v1.User users */ 1:
                    message.users.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated plan.v1.Plan plans */ 2:
                    message.plans.push(Plan.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAllUsersAndPlansResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated base.v1.User users = 1; */
        for (let i = 0; i < message.users.length; i++)
            User.internalBinaryWrite(message.users[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated plan.v1.Plan plans = 2; */
        for (let i = 0; i < message.plans.length; i++)
            Plan.internalBinaryWrite(message.plans[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message aggregate.v1.ListAllUsersAndPlansResponse
 */
export const ListAllUsersAndPlansResponse = new ListAllUsersAndPlansResponse$Type();
/**
 * @generated ServiceType for protobuf service aggregate.v1.AggregateAPI
 */
export const AggregateAPI = new ServiceType("aggregate.v1.AggregateAPI", [
    { name: "GetUserAggregateProfile", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: GetUserAggregateProfileRequest, O: GetUserAggregateProfileResponse },
    { name: "GetBoardAggregateRooms", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: GetBoardAggregateRoomsRequest, O: GetBoardAggregateRoomsResponse },
    { name: "ListAllUsersWithSubscription", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_ADMIN"] } }, I: ListAllUsersWithSubscriptionRequest, O: ListAllUsersWithSubscriptionResponse },
    { name: "ListAllUsersAndPlans", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_ADMIN"] } }, I: ListAllUsersAndPlansRequest, O: ListAllUsersAndPlansResponse }
], { "base.v1.service_permission": { requireAuth: true } });
