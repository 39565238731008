import {
  GetUserAggregateProfileRequest,
  GetUserAggregateProfileResponse,
  GetBoardAggregateRoomsRequest,
  GetBoardAggregateRoomsResponse,
  ListAllUsersWithSubscriptionRequest,
  ListAllUsersWithSubscriptionResponse,
  ListAllUsersAndPlansRequest,
  ListAllUsersAndPlansResponse,
} from "./aggregate_api";

//==================================//
//          Client Code             //
//==================================//

interface Rpc {
  request(
    service: string,
    method: string,
    contentType: "application/json" | "application/protobuf",
    data: object | Uint8Array
  ): Promise<object | Uint8Array>;
}

export interface AggregateAPIClient {
  GetUserAggregateProfile(
    request: GetUserAggregateProfileRequest
  ): Promise<GetUserAggregateProfileResponse>;
  GetBoardAggregateRooms(
    request: GetBoardAggregateRoomsRequest
  ): Promise<GetBoardAggregateRoomsResponse>;
  ListAllUsersWithSubscription(
    request: ListAllUsersWithSubscriptionRequest
  ): Promise<ListAllUsersWithSubscriptionResponse>;
  ListAllUsersAndPlans(
    request: ListAllUsersAndPlansRequest
  ): Promise<ListAllUsersAndPlansResponse>;
}

export class AggregateAPIClientJSON implements AggregateAPIClient {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetUserAggregateProfile.bind(this);
    this.GetBoardAggregateRooms.bind(this);
    this.ListAllUsersWithSubscription.bind(this);
    this.ListAllUsersAndPlans.bind(this);
  }
  GetUserAggregateProfile(
    request: GetUserAggregateProfileRequest
  ): Promise<GetUserAggregateProfileResponse> {
    const data = GetUserAggregateProfileRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "aggregate.v1.AggregateAPI",
      "GetUserAggregateProfile",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      GetUserAggregateProfileResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }

  GetBoardAggregateRooms(
    request: GetBoardAggregateRoomsRequest
  ): Promise<GetBoardAggregateRoomsResponse> {
    const data = GetBoardAggregateRoomsRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "aggregate.v1.AggregateAPI",
      "GetBoardAggregateRooms",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      GetBoardAggregateRoomsResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }

  ListAllUsersWithSubscription(
    request: ListAllUsersWithSubscriptionRequest
  ): Promise<ListAllUsersWithSubscriptionResponse> {
    const data = ListAllUsersWithSubscriptionRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "aggregate.v1.AggregateAPI",
      "ListAllUsersWithSubscription",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      ListAllUsersWithSubscriptionResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }

  ListAllUsersAndPlans(
    request: ListAllUsersAndPlansRequest
  ): Promise<ListAllUsersAndPlansResponse> {
    const data = ListAllUsersAndPlansRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "aggregate.v1.AggregateAPI",
      "ListAllUsersAndPlans",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      ListAllUsersAndPlansResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }
}

export class AggregateAPIClientProtobuf implements AggregateAPIClient {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetUserAggregateProfile.bind(this);
    this.GetBoardAggregateRooms.bind(this);
    this.ListAllUsersWithSubscription.bind(this);
    this.ListAllUsersAndPlans.bind(this);
  }
  GetUserAggregateProfile(
    request: GetUserAggregateProfileRequest
  ): Promise<GetUserAggregateProfileResponse> {
    const data = GetUserAggregateProfileRequest.toBinary(request);
    const promise = this.rpc.request(
      "aggregate.v1.AggregateAPI",
      "GetUserAggregateProfile",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      GetUserAggregateProfileResponse.fromBinary(data as Uint8Array)
    );
  }

  GetBoardAggregateRooms(
    request: GetBoardAggregateRoomsRequest
  ): Promise<GetBoardAggregateRoomsResponse> {
    const data = GetBoardAggregateRoomsRequest.toBinary(request);
    const promise = this.rpc.request(
      "aggregate.v1.AggregateAPI",
      "GetBoardAggregateRooms",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      GetBoardAggregateRoomsResponse.fromBinary(data as Uint8Array)
    );
  }

  ListAllUsersWithSubscription(
    request: ListAllUsersWithSubscriptionRequest
  ): Promise<ListAllUsersWithSubscriptionResponse> {
    const data = ListAllUsersWithSubscriptionRequest.toBinary(request);
    const promise = this.rpc.request(
      "aggregate.v1.AggregateAPI",
      "ListAllUsersWithSubscription",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      ListAllUsersWithSubscriptionResponse.fromBinary(data as Uint8Array)
    );
  }

  ListAllUsersAndPlans(
    request: ListAllUsersAndPlansRequest
  ): Promise<ListAllUsersAndPlansResponse> {
    const data = ListAllUsersAndPlansRequest.toBinary(request);
    const promise = this.rpc.request(
      "aggregate.v1.AggregateAPI",
      "ListAllUsersAndPlans",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      ListAllUsersAndPlansResponse.fromBinary(data as Uint8Array)
    );
  }
}
