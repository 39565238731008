// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "whiteboard/board/v1/board_api.proto" (package "whiteboard.board.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Board } from "./board";
import { ListBoard } from "./board";
import { SortDirection } from "../../../base/v1/sort";
/**
 * @generated from protobuf message whiteboard.board.v1.CreateBoardRequest
 */
export interface CreateBoardRequest {
    /**
     * @generated from protobuf field: string resource_id = 1;
     */
    resourceId: string;
    /**
     * @generated from protobuf field: bool is_clone = 2;
     */
    isClone: boolean;
}
/**
 * @generated from protobuf message whiteboard.board.v1.CreateBoardResponse
 */
export interface CreateBoardResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message whiteboard.board.v1.AcquireBoardLockRequest
 */
export interface AcquireBoardLockRequest {
    /**
     * @generated from protobuf field: string board_id = 1;
     */
    boardId: string;
}
/**
 * @generated from protobuf message whiteboard.board.v1.AcquireBoardLockResponse
 */
export interface AcquireBoardLockResponse {
}
/**
 * @generated from protobuf message whiteboard.board.v1.ReleaseBoardLockRequest
 */
export interface ReleaseBoardLockRequest {
    /**
     * @generated from protobuf field: string board_id = 1;
     */
    boardId: string;
}
/**
 * @generated from protobuf message whiteboard.board.v1.ReleaseBoardLockResponse
 */
export interface ReleaseBoardLockResponse {
}
/**
 * @generated from protobuf message whiteboard.board.v1.ListBoardsRequest
 */
export interface ListBoardsRequest {
    /**
     * which page to list
     *
     * @generated from protobuf field: int32 page = 1;
     */
    page: number;
    /**
     * size of the page
     *
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
    /**
     * sort direction
     *
     * @generated from protobuf field: base.v1.SortDirection sort_direction = 3;
     */
    sortDirection: SortDirection;
    /**
     * @generated from protobuf field: string room_id = 4;
     */
    roomId: string;
}
/**
 * @generated from protobuf message whiteboard.board.v1.ListBoardsResponse
 */
export interface ListBoardsResponse {
    /**
     * @generated from protobuf field: repeated whiteboard.board.v1.ListBoard boards = 1;
     */
    boards: ListBoard[];
}
/**
 * @generated from protobuf message whiteboard.board.v1.GetBoardRequest
 */
export interface GetBoardRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string room_id = 2;
     */
    roomId: string;
}
/**
 * @generated from protobuf message whiteboard.board.v1.GetBoardResponse
 */
export interface GetBoardResponse {
    /**
     * @generated from protobuf field: whiteboard.board.v1.Board board = 1;
     */
    board?: Board;
}
/**
 * @generated from protobuf message whiteboard.board.v1.UpdateBoardDataRequest
 */
export interface UpdateBoardDataRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: bytes data = 2;
     */
    data: Uint8Array;
    /**
     * @generated from protobuf field: bytes preview_image = 3;
     */
    previewImage: Uint8Array;
    /**
     * @generated from protobuf field: string title = 4;
     */
    title: string;
    /**
     * @generated from protobuf field: string room_id = 5;
     */
    roomId: string;
}
/**
 * @generated from protobuf message whiteboard.board.v1.UpdateBoardDataResponse
 */
export interface UpdateBoardDataResponse {
}
/**
 * @generated from protobuf message whiteboard.board.v1.DeleteBoardRequest
 */
export interface DeleteBoardRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: bool delete_files = 2;
     */
    deleteFiles: boolean;
    /**
     * @generated from protobuf field: string room_id = 3;
     */
    roomId: string;
}
/**
 * @generated from protobuf message whiteboard.board.v1.DeleteBoardResponse
 */
export interface DeleteBoardResponse {
}
/**
 * @generated from protobuf message whiteboard.board.v1.GetBoardRoomsRequest
 */
export interface GetBoardRoomsRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message whiteboard.board.v1.GetBoardRoomsResponse
 */
export interface GetBoardRoomsResponse {
    /**
     * @generated from protobuf field: repeated string room_ids = 1;
     */
    roomIds: string[];
}
/**
 * @generated from protobuf message whiteboard.board.v1.UpdateBoardRoomsRequest
 */
export interface UpdateBoardRoomsRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: repeated string to_add_room_ids = 2;
     */
    toAddRoomIds: string[];
    /**
     * @generated from protobuf field: repeated string to_remove_room_ids = 3;
     */
    toRemoveRoomIds: string[];
}
/**
 * @generated from protobuf message whiteboard.board.v1.UpdateBoardRoomsResponse
 */
export interface UpdateBoardRoomsResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class CreateBoardRequest$Type extends MessageType<CreateBoardRequest> {
    constructor() {
        super("whiteboard.board.v1.CreateBoardRequest", [
            { no: 1, name: "resource_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "is_clone", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CreateBoardRequest>): CreateBoardRequest {
        const message = { resourceId: "", isClone: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateBoardRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateBoardRequest): CreateBoardRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string resource_id */ 1:
                    message.resourceId = reader.string();
                    break;
                case /* bool is_clone */ 2:
                    message.isClone = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateBoardRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string resource_id = 1; */
        if (message.resourceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.resourceId);
        /* bool is_clone = 2; */
        if (message.isClone !== false)
            writer.tag(2, WireType.Varint).bool(message.isClone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.CreateBoardRequest
 */
export const CreateBoardRequest = new CreateBoardRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateBoardResponse$Type extends MessageType<CreateBoardResponse> {
    constructor() {
        super("whiteboard.board.v1.CreateBoardResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateBoardResponse>): CreateBoardResponse {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateBoardResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateBoardResponse): CreateBoardResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateBoardResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.CreateBoardResponse
 */
export const CreateBoardResponse = new CreateBoardResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcquireBoardLockRequest$Type extends MessageType<AcquireBoardLockRequest> {
    constructor() {
        super("whiteboard.board.v1.AcquireBoardLockRequest", [
            { no: 1, name: "board_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AcquireBoardLockRequest>): AcquireBoardLockRequest {
        const message = { boardId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AcquireBoardLockRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AcquireBoardLockRequest): AcquireBoardLockRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string board_id */ 1:
                    message.boardId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AcquireBoardLockRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string board_id = 1; */
        if (message.boardId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.boardId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.AcquireBoardLockRequest
 */
export const AcquireBoardLockRequest = new AcquireBoardLockRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcquireBoardLockResponse$Type extends MessageType<AcquireBoardLockResponse> {
    constructor() {
        super("whiteboard.board.v1.AcquireBoardLockResponse", []);
    }
    create(value?: PartialMessage<AcquireBoardLockResponse>): AcquireBoardLockResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AcquireBoardLockResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AcquireBoardLockResponse): AcquireBoardLockResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AcquireBoardLockResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.AcquireBoardLockResponse
 */
export const AcquireBoardLockResponse = new AcquireBoardLockResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReleaseBoardLockRequest$Type extends MessageType<ReleaseBoardLockRequest> {
    constructor() {
        super("whiteboard.board.v1.ReleaseBoardLockRequest", [
            { no: 1, name: "board_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReleaseBoardLockRequest>): ReleaseBoardLockRequest {
        const message = { boardId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReleaseBoardLockRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReleaseBoardLockRequest): ReleaseBoardLockRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string board_id */ 1:
                    message.boardId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReleaseBoardLockRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string board_id = 1; */
        if (message.boardId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.boardId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.ReleaseBoardLockRequest
 */
export const ReleaseBoardLockRequest = new ReleaseBoardLockRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReleaseBoardLockResponse$Type extends MessageType<ReleaseBoardLockResponse> {
    constructor() {
        super("whiteboard.board.v1.ReleaseBoardLockResponse", []);
    }
    create(value?: PartialMessage<ReleaseBoardLockResponse>): ReleaseBoardLockResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReleaseBoardLockResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReleaseBoardLockResponse): ReleaseBoardLockResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReleaseBoardLockResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.ReleaseBoardLockResponse
 */
export const ReleaseBoardLockResponse = new ReleaseBoardLockResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListBoardsRequest$Type extends MessageType<ListBoardsRequest> {
    constructor() {
        super("whiteboard.board.v1.ListBoardsRequest", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "sort_direction", kind: "enum", T: () => ["base.v1.SortDirection", SortDirection, "SORT_DIRECTION_"] },
            { no: 4, name: "room_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListBoardsRequest>): ListBoardsRequest {
        const message = { page: 0, pageSize: 0, sortDirection: 0, roomId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListBoardsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListBoardsRequest): ListBoardsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 1:
                    message.page = reader.int32();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* base.v1.SortDirection sort_direction */ 3:
                    message.sortDirection = reader.int32();
                    break;
                case /* string room_id */ 4:
                    message.roomId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListBoardsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int32(message.page);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* base.v1.SortDirection sort_direction = 3; */
        if (message.sortDirection !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortDirection);
        /* string room_id = 4; */
        if (message.roomId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.roomId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.ListBoardsRequest
 */
export const ListBoardsRequest = new ListBoardsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListBoardsResponse$Type extends MessageType<ListBoardsResponse> {
    constructor() {
        super("whiteboard.board.v1.ListBoardsResponse", [
            { no: 1, name: "boards", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListBoard }
        ]);
    }
    create(value?: PartialMessage<ListBoardsResponse>): ListBoardsResponse {
        const message = { boards: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListBoardsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListBoardsResponse): ListBoardsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated whiteboard.board.v1.ListBoard boards */ 1:
                    message.boards.push(ListBoard.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListBoardsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated whiteboard.board.v1.ListBoard boards = 1; */
        for (let i = 0; i < message.boards.length; i++)
            ListBoard.internalBinaryWrite(message.boards[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.ListBoardsResponse
 */
export const ListBoardsResponse = new ListBoardsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBoardRequest$Type extends MessageType<GetBoardRequest> {
    constructor() {
        super("whiteboard.board.v1.GetBoardRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "room_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetBoardRequest>): GetBoardRequest {
        const message = { id: "", roomId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBoardRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBoardRequest): GetBoardRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string room_id */ 2:
                    message.roomId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBoardRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string room_id = 2; */
        if (message.roomId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.roomId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.GetBoardRequest
 */
export const GetBoardRequest = new GetBoardRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBoardResponse$Type extends MessageType<GetBoardResponse> {
    constructor() {
        super("whiteboard.board.v1.GetBoardResponse", [
            { no: 1, name: "board", kind: "message", T: () => Board }
        ]);
    }
    create(value?: PartialMessage<GetBoardResponse>): GetBoardResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBoardResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBoardResponse): GetBoardResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* whiteboard.board.v1.Board board */ 1:
                    message.board = Board.internalBinaryRead(reader, reader.uint32(), options, message.board);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBoardResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* whiteboard.board.v1.Board board = 1; */
        if (message.board)
            Board.internalBinaryWrite(message.board, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.GetBoardResponse
 */
export const GetBoardResponse = new GetBoardResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBoardDataRequest$Type extends MessageType<UpdateBoardDataRequest> {
    constructor() {
        super("whiteboard.board.v1.UpdateBoardDataRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 3, name: "preview_image", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 4, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "room_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateBoardDataRequest>): UpdateBoardDataRequest {
        const message = { id: "", data: new Uint8Array(0), previewImage: new Uint8Array(0), title: "", roomId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateBoardDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateBoardDataRequest): UpdateBoardDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bytes data */ 2:
                    message.data = reader.bytes();
                    break;
                case /* bytes preview_image */ 3:
                    message.previewImage = reader.bytes();
                    break;
                case /* string title */ 4:
                    message.title = reader.string();
                    break;
                case /* string room_id */ 5:
                    message.roomId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateBoardDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bytes data = 2; */
        if (message.data.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.data);
        /* bytes preview_image = 3; */
        if (message.previewImage.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.previewImage);
        /* string title = 4; */
        if (message.title !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.title);
        /* string room_id = 5; */
        if (message.roomId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.roomId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.UpdateBoardDataRequest
 */
export const UpdateBoardDataRequest = new UpdateBoardDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBoardDataResponse$Type extends MessageType<UpdateBoardDataResponse> {
    constructor() {
        super("whiteboard.board.v1.UpdateBoardDataResponse", []);
    }
    create(value?: PartialMessage<UpdateBoardDataResponse>): UpdateBoardDataResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateBoardDataResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateBoardDataResponse): UpdateBoardDataResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateBoardDataResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.UpdateBoardDataResponse
 */
export const UpdateBoardDataResponse = new UpdateBoardDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteBoardRequest$Type extends MessageType<DeleteBoardRequest> {
    constructor() {
        super("whiteboard.board.v1.DeleteBoardRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "delete_files", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "room_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteBoardRequest>): DeleteBoardRequest {
        const message = { id: "", deleteFiles: false, roomId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteBoardRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteBoardRequest): DeleteBoardRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bool delete_files */ 2:
                    message.deleteFiles = reader.bool();
                    break;
                case /* string room_id */ 3:
                    message.roomId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteBoardRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bool delete_files = 2; */
        if (message.deleteFiles !== false)
            writer.tag(2, WireType.Varint).bool(message.deleteFiles);
        /* string room_id = 3; */
        if (message.roomId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.roomId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.DeleteBoardRequest
 */
export const DeleteBoardRequest = new DeleteBoardRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteBoardResponse$Type extends MessageType<DeleteBoardResponse> {
    constructor() {
        super("whiteboard.board.v1.DeleteBoardResponse", []);
    }
    create(value?: PartialMessage<DeleteBoardResponse>): DeleteBoardResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteBoardResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteBoardResponse): DeleteBoardResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteBoardResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.DeleteBoardResponse
 */
export const DeleteBoardResponse = new DeleteBoardResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBoardRoomsRequest$Type extends MessageType<GetBoardRoomsRequest> {
    constructor() {
        super("whiteboard.board.v1.GetBoardRoomsRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetBoardRoomsRequest>): GetBoardRoomsRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBoardRoomsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBoardRoomsRequest): GetBoardRoomsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBoardRoomsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.GetBoardRoomsRequest
 */
export const GetBoardRoomsRequest = new GetBoardRoomsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBoardRoomsResponse$Type extends MessageType<GetBoardRoomsResponse> {
    constructor() {
        super("whiteboard.board.v1.GetBoardRoomsResponse", [
            { no: 1, name: "room_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetBoardRoomsResponse>): GetBoardRoomsResponse {
        const message = { roomIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBoardRoomsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBoardRoomsResponse): GetBoardRoomsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string room_ids */ 1:
                    message.roomIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBoardRoomsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string room_ids = 1; */
        for (let i = 0; i < message.roomIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.roomIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.GetBoardRoomsResponse
 */
export const GetBoardRoomsResponse = new GetBoardRoomsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBoardRoomsRequest$Type extends MessageType<UpdateBoardRoomsRequest> {
    constructor() {
        super("whiteboard.board.v1.UpdateBoardRoomsRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "to_add_room_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "to_remove_room_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateBoardRoomsRequest>): UpdateBoardRoomsRequest {
        const message = { id: "", toAddRoomIds: [], toRemoveRoomIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateBoardRoomsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateBoardRoomsRequest): UpdateBoardRoomsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* repeated string to_add_room_ids */ 2:
                    message.toAddRoomIds.push(reader.string());
                    break;
                case /* repeated string to_remove_room_ids */ 3:
                    message.toRemoveRoomIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateBoardRoomsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* repeated string to_add_room_ids = 2; */
        for (let i = 0; i < message.toAddRoomIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.toAddRoomIds[i]);
        /* repeated string to_remove_room_ids = 3; */
        for (let i = 0; i < message.toRemoveRoomIds.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.toRemoveRoomIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.UpdateBoardRoomsRequest
 */
export const UpdateBoardRoomsRequest = new UpdateBoardRoomsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBoardRoomsResponse$Type extends MessageType<UpdateBoardRoomsResponse> {
    constructor() {
        super("whiteboard.board.v1.UpdateBoardRoomsResponse", []);
    }
    create(value?: PartialMessage<UpdateBoardRoomsResponse>): UpdateBoardRoomsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateBoardRoomsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateBoardRoomsResponse): UpdateBoardRoomsResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateBoardRoomsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.UpdateBoardRoomsResponse
 */
export const UpdateBoardRoomsResponse = new UpdateBoardRoomsResponse$Type();
/**
 * @generated ServiceType for protobuf service whiteboard.board.v1.BoardAPI
 */
export const BoardAPI = new ServiceType("whiteboard.board.v1.BoardAPI", [
    { name: "ListBoards", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: ListBoardsRequest, O: ListBoardsResponse },
    { name: "GetBoard", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: GetBoardRequest, O: GetBoardResponse },
    { name: "CreateBoard", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: CreateBoardRequest, O: CreateBoardResponse },
    { name: "AcquireBoardLock", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: AcquireBoardLockRequest, O: AcquireBoardLockResponse },
    { name: "ReleaseBoardLock", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: ReleaseBoardLockRequest, O: ReleaseBoardLockResponse },
    { name: "UpdateBoardData", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: UpdateBoardDataRequest, O: UpdateBoardDataResponse },
    { name: "DeleteBoard", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: DeleteBoardRequest, O: DeleteBoardResponse },
    { name: "GetBoardRooms", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: GetBoardRoomsRequest, O: GetBoardRoomsResponse },
    { name: "UpdateBoardRooms", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: UpdateBoardRoomsRequest, O: UpdateBoardRoomsResponse }
], { "base.v1.service_permission": { requireAuth: true } });
