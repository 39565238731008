import { aggAPIClient } from "clients";
import { useCallback, useEffect, useState } from "react";
import constate from "constate";
import { useQuery } from "react-query";
import { TwirpError } from "twirp-ts";
import { GetUserAggregateProfileResponse } from "proto/aggregate/v1/aggregate_api";
import { useLocalStorage } from "./useLocalStorage";
import { Timestamp } from "proto/google/protobuf/timestamp";

const useAggProfile = (notificationCreatedOnAfter?: Timestamp) => {
  const fetchData = useCallback((notificationCreatedOnAfter?: Timestamp) => {
    return new Promise<GetUserAggregateProfileResponse>(
      async (resolve, reject) => {
        console.log("try getting agg profile");
        try {
          const data = await aggAPIClient.GetUserAggregateProfile({
            notificationCreatedOnAfter,
          });
          resolve(data);
        } catch (error) {
          console.log("useAggProfile error", error);
          reject(error as TwirpError);
        }
      }
    );
  }, []);
  const { isLoading, isFetching, isError, error, data, refetch } = useQuery<
    GetUserAggregateProfileResponse,
    TwirpError
  >(
    ["GetUserAggregateProfileGlobal", notificationCreatedOnAfter],
    () => fetchData(notificationCreatedOnAfter),
    {
      keepPreviousData: true,
    }
  );

  return {
    isLoading: isLoading || isFetching,
    isError,
    error,
    data,
    refetch,
  };
};

const useGlobalState = () => {
  const [lastNotificationDate, setLastNotificationDate] = useLocalStorage<
    Timestamp | undefined
  >("reticulo:whiteboard:lastNotificationDate", {
    nanos: 0,
    seconds: 0,
  });
  const [loaded, setLoaded] = useState(false);
  const { data, error, isError, isLoading, refetch } =
    useAggProfile(lastNotificationDate);

  // we will only show loading for the time we fetch data
  useEffect(() => {
    if (loaded || isLoading) return;
    setLoaded(true);
  }, [loaded, isLoading]);

  const updateLastNotificationDate = useCallback(() => {
    setLastNotificationDate(data?.lastNotificationDate);
  }, [data?.lastNotificationDate, setLastNotificationDate]);

  return {
    data,
    error,
    isError,
    loaded,
    isFetching: isLoading,
    refetch,
    updateLastNotificationDate,
  };
};

const [GlobalStateProvider, useGlobalStateContext] = constate(useGlobalState);

export { GlobalStateProvider, useGlobalStateContext };
