// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "base/v1/notifcation.proto" (package "base.v1", syntax proto3)
// tslint:disable
/**
 * @generated from protobuf enum base.v1.NotificationType
 */
export enum NotificationType {
    /**
     * @generated from protobuf enum value: NOTIFICATION_TYPE_INVALID = 0;
     */
    INVALID = 0,
    /**
     * @generated from protobuf enum value: NOTIFICATION_TYPE_ADDED_TO_ROOM = 1;
     */
    ADDED_TO_ROOM = 1,
    /**
     * @generated from protobuf enum value: NOTIFICATION_TYPE_REMOVED_FROM_ROOM = 2;
     */
    REMOVED_FROM_ROOM = 2,
    /**
     * @generated from protobuf enum value: NOTIFICATION_TYPE_BOARD_ADDED_TO_ROOM = 3;
     */
    BOARD_ADDED_TO_ROOM = 3
}
