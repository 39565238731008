// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "template/v1/template_api.proto" (package "template.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Template } from "./template";
import { ListTemplate } from "./template";
import { SortDirection } from "../../base/v1/sort";
/**
 * @generated from protobuf message template.v1.CreateTemplateRequest
 */
export interface CreateTemplateRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: bytes data = 2;
     */
    data: Uint8Array;
    /**
     * @generated from protobuf field: bytes preview_image = 3;
     */
    previewImage: Uint8Array;
    /**
     * @generated from protobuf field: string description = 4;
     */
    description: string;
}
/**
 * @generated from protobuf message template.v1.CreateTemplateResponse
 */
export interface CreateTemplateResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message template.v1.ListTemplatesRequest
 */
export interface ListTemplatesRequest {
    /**
     * which page to list
     *
     * @generated from protobuf field: int32 page = 1;
     */
    page: number;
    /**
     * size of the page
     *
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
    /**
     * sort direction
     *
     * @generated from protobuf field: base.v1.SortDirection sort_direction = 3;
     */
    sortDirection: SortDirection;
}
/**
 * @generated from protobuf message template.v1.ListTemplatesResponse
 */
export interface ListTemplatesResponse {
    /**
     * @generated from protobuf field: repeated template.v1.ListTemplate templates = 1;
     */
    templates: ListTemplate[];
}
/**
 * @generated from protobuf message template.v1.GetTemplateRequest
 */
export interface GetTemplateRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message template.v1.GetTemplateResponse
 */
export interface GetTemplateResponse {
    /**
     * @generated from protobuf field: template.v1.Template template = 1;
     */
    template?: Template;
}
/**
 * @generated from protobuf message template.v1.DeleteTemplateRequest
 */
export interface DeleteTemplateRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: bool delete_files = 2;
     */
    deleteFiles: boolean;
}
/**
 * @generated from protobuf message template.v1.DeleteTemplateResponse
 */
export interface DeleteTemplateResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class CreateTemplateRequest$Type extends MessageType<CreateTemplateRequest> {
    constructor() {
        super("template.v1.CreateTemplateRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 3, name: "preview_image", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateTemplateRequest>): CreateTemplateRequest {
        const message = { name: "", data: new Uint8Array(0), previewImage: new Uint8Array(0), description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateTemplateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateTemplateRequest): CreateTemplateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* bytes data */ 2:
                    message.data = reader.bytes();
                    break;
                case /* bytes preview_image */ 3:
                    message.previewImage = reader.bytes();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateTemplateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* bytes data = 2; */
        if (message.data.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.data);
        /* bytes preview_image = 3; */
        if (message.previewImage.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.previewImage);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message template.v1.CreateTemplateRequest
 */
export const CreateTemplateRequest = new CreateTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateTemplateResponse$Type extends MessageType<CreateTemplateResponse> {
    constructor() {
        super("template.v1.CreateTemplateResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateTemplateResponse>): CreateTemplateResponse {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateTemplateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateTemplateResponse): CreateTemplateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateTemplateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message template.v1.CreateTemplateResponse
 */
export const CreateTemplateResponse = new CreateTemplateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTemplatesRequest$Type extends MessageType<ListTemplatesRequest> {
    constructor() {
        super("template.v1.ListTemplatesRequest", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "sort_direction", kind: "enum", T: () => ["base.v1.SortDirection", SortDirection, "SORT_DIRECTION_"] }
        ]);
    }
    create(value?: PartialMessage<ListTemplatesRequest>): ListTemplatesRequest {
        const message = { page: 0, pageSize: 0, sortDirection: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListTemplatesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTemplatesRequest): ListTemplatesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 1:
                    message.page = reader.int32();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* base.v1.SortDirection sort_direction */ 3:
                    message.sortDirection = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTemplatesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int32(message.page);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* base.v1.SortDirection sort_direction = 3; */
        if (message.sortDirection !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortDirection);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message template.v1.ListTemplatesRequest
 */
export const ListTemplatesRequest = new ListTemplatesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTemplatesResponse$Type extends MessageType<ListTemplatesResponse> {
    constructor() {
        super("template.v1.ListTemplatesResponse", [
            { no: 1, name: "templates", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListTemplate }
        ]);
    }
    create(value?: PartialMessage<ListTemplatesResponse>): ListTemplatesResponse {
        const message = { templates: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListTemplatesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTemplatesResponse): ListTemplatesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated template.v1.ListTemplate templates */ 1:
                    message.templates.push(ListTemplate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTemplatesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated template.v1.ListTemplate templates = 1; */
        for (let i = 0; i < message.templates.length; i++)
            ListTemplate.internalBinaryWrite(message.templates[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message template.v1.ListTemplatesResponse
 */
export const ListTemplatesResponse = new ListTemplatesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTemplateRequest$Type extends MessageType<GetTemplateRequest> {
    constructor() {
        super("template.v1.GetTemplateRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTemplateRequest>): GetTemplateRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTemplateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTemplateRequest): GetTemplateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTemplateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message template.v1.GetTemplateRequest
 */
export const GetTemplateRequest = new GetTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTemplateResponse$Type extends MessageType<GetTemplateResponse> {
    constructor() {
        super("template.v1.GetTemplateResponse", [
            { no: 1, name: "template", kind: "message", T: () => Template }
        ]);
    }
    create(value?: PartialMessage<GetTemplateResponse>): GetTemplateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTemplateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTemplateResponse): GetTemplateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* template.v1.Template template */ 1:
                    message.template = Template.internalBinaryRead(reader, reader.uint32(), options, message.template);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTemplateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* template.v1.Template template = 1; */
        if (message.template)
            Template.internalBinaryWrite(message.template, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message template.v1.GetTemplateResponse
 */
export const GetTemplateResponse = new GetTemplateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteTemplateRequest$Type extends MessageType<DeleteTemplateRequest> {
    constructor() {
        super("template.v1.DeleteTemplateRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "delete_files", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteTemplateRequest>): DeleteTemplateRequest {
        const message = { id: "", deleteFiles: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteTemplateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteTemplateRequest): DeleteTemplateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bool delete_files */ 2:
                    message.deleteFiles = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteTemplateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bool delete_files = 2; */
        if (message.deleteFiles !== false)
            writer.tag(2, WireType.Varint).bool(message.deleteFiles);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message template.v1.DeleteTemplateRequest
 */
export const DeleteTemplateRequest = new DeleteTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteTemplateResponse$Type extends MessageType<DeleteTemplateResponse> {
    constructor() {
        super("template.v1.DeleteTemplateResponse", []);
    }
    create(value?: PartialMessage<DeleteTemplateResponse>): DeleteTemplateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteTemplateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteTemplateResponse): DeleteTemplateResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteTemplateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message template.v1.DeleteTemplateResponse
 */
export const DeleteTemplateResponse = new DeleteTemplateResponse$Type();
/**
 * @generated ServiceType for protobuf service template.v1.TemplateAPI
 */
export const TemplateAPI = new ServiceType("template.v1.TemplateAPI", [
    { name: "ListTemplates", options: {}, I: ListTemplatesRequest, O: ListTemplatesResponse },
    { name: "GetTemplate", options: {}, I: GetTemplateRequest, O: GetTemplateResponse },
    { name: "CreateTemplate", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_ADMIN"] } }, I: CreateTemplateRequest, O: CreateTemplateResponse },
    { name: "DeleteTemplate", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_ADMIN"] } }, I: DeleteTemplateRequest, O: DeleteTemplateResponse }
], { "base.v1.service_permission": { requireAuth: true } });
