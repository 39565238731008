import {
  ListNotificationsRequest,
  ListNotificationsResponse,
  GetNotificationsCountRequest,
  GetNotificationsCountResponse,
} from "./notification_api";

//==================================//
//          Client Code             //
//==================================//

interface Rpc {
  request(
    service: string,
    method: string,
    contentType: "application/json" | "application/protobuf",
    data: object | Uint8Array
  ): Promise<object | Uint8Array>;
}

export interface NotificationAPIClient {
  ListNotifications(
    request: ListNotificationsRequest
  ): Promise<ListNotificationsResponse>;
  GetNotificationsCount(
    request: GetNotificationsCountRequest
  ): Promise<GetNotificationsCountResponse>;
}

export class NotificationAPIClientJSON implements NotificationAPIClient {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ListNotifications.bind(this);
    this.GetNotificationsCount.bind(this);
  }
  ListNotifications(
    request: ListNotificationsRequest
  ): Promise<ListNotificationsResponse> {
    const data = ListNotificationsRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "notification.v1.NotificationAPI",
      "ListNotifications",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      ListNotificationsResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }

  GetNotificationsCount(
    request: GetNotificationsCountRequest
  ): Promise<GetNotificationsCountResponse> {
    const data = GetNotificationsCountRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "notification.v1.NotificationAPI",
      "GetNotificationsCount",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      GetNotificationsCountResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }
}

export class NotificationAPIClientProtobuf implements NotificationAPIClient {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ListNotifications.bind(this);
    this.GetNotificationsCount.bind(this);
  }
  ListNotifications(
    request: ListNotificationsRequest
  ): Promise<ListNotificationsResponse> {
    const data = ListNotificationsRequest.toBinary(request);
    const promise = this.rpc.request(
      "notification.v1.NotificationAPI",
      "ListNotifications",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      ListNotificationsResponse.fromBinary(data as Uint8Array)
    );
  }

  GetNotificationsCount(
    request: GetNotificationsCountRequest
  ): Promise<GetNotificationsCountResponse> {
    const data = GetNotificationsCountRequest.toBinary(request);
    const promise = this.rpc.request(
      "notification.v1.NotificationAPI",
      "GetNotificationsCount",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      GetNotificationsCountResponse.fromBinary(data as Uint8Array)
    );
  }
}
