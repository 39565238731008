import axios from "axios";
import Board from "supertokens-auth-react/recipe/session";
import { TwirpError } from "twirp-ts";

export const apiPath =
  process.env.REACT_APP_API_BASE_PATH ?? "http://localhost:9000/api";

const client = axios.create({
  baseURL: apiPath,
});

Board.addAxiosInterceptors(client);

interface Rpc {
  request(
    service: string,
    method: string,
    contentType: "application/json" | "application/protobuf",
    data: object | Uint8Array
  ): Promise<object | Uint8Array>;
}

export const AxiosRPC: Rpc = {
  request(service, method, contentType, data) {
    return client
      .post(`${service}/${method}`, data, {
        responseType:
          contentType === "application/protobuf" ? "arraybuffer" : "json",
        headers: {
          "content-type": contentType,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw TwirpError.fromObject(error.response.data);
      });
  },
};
