import { useCallback } from "react";
import { signOut } from "supertokens-auth-react/recipe/emailpassword";

export const useLogout = () => {
  const logout = useCallback(async () => {
    await signOut();
    window.location.reload();
  }, []);

  return logout;
};
