// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "template/v1/template.proto" (package "template.v1", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../google/protobuf/timestamp";
import { Orientation } from "../../base/v1/whiteboard";
import { Preset } from "../../base/v1/whiteboard";
/**
 * @generated from protobuf message template.v1.Template
 */
export interface Template {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: bytes data = 3;
     */
    data: Uint8Array;
    /**
     * @generated from protobuf field: string preview_image_id = 4;
     */
    previewImageId: string;
    /**
     * @generated from protobuf field: base.v1.Preset preset = 5;
     */
    preset: Preset;
    /**
     * @generated from protobuf field: base.v1.Orientation orientation = 6;
     */
    orientation: Orientation;
    /**
     * @generated from protobuf field: string description = 8;
     */
    description: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 9;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 10;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: int32 sequence_no = 11;
     */
    sequenceNo: number;
}
/**
 * @generated from protobuf message template.v1.ListTemplate
 */
export interface ListTemplate {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string preview_image_id = 3;
     */
    previewImageId: string;
    /**
     * @generated from protobuf field: base.v1.Preset preset = 4;
     */
    preset: Preset;
    /**
     * @generated from protobuf field: base.v1.Orientation orientation = 5;
     */
    orientation: Orientation;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 7;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 8;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: int32 sequence_no = 9;
     */
    sequenceNo: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class Template$Type extends MessageType<Template> {
    constructor() {
        super("template.v1.Template", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 4, name: "preview_image_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "preset", kind: "enum", T: () => ["base.v1.Preset", Preset] },
            { no: 6, name: "orientation", kind: "enum", T: () => ["base.v1.Orientation", Orientation, "ORIENTATION_"] },
            { no: 8, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 10, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 11, name: "sequence_no", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Template>): Template {
        const message = { id: "", name: "", data: new Uint8Array(0), previewImageId: "", preset: 0, orientation: 0, description: "", sequenceNo: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Template>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Template): Template {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* bytes data */ 3:
                    message.data = reader.bytes();
                    break;
                case /* string preview_image_id */ 4:
                    message.previewImageId = reader.string();
                    break;
                case /* base.v1.Preset preset */ 5:
                    message.preset = reader.int32();
                    break;
                case /* base.v1.Orientation orientation */ 6:
                    message.orientation = reader.int32();
                    break;
                case /* string description */ 8:
                    message.description = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 9:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 10:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* int32 sequence_no */ 11:
                    message.sequenceNo = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Template, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* bytes data = 3; */
        if (message.data.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.data);
        /* string preview_image_id = 4; */
        if (message.previewImageId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.previewImageId);
        /* base.v1.Preset preset = 5; */
        if (message.preset !== 0)
            writer.tag(5, WireType.Varint).int32(message.preset);
        /* base.v1.Orientation orientation = 6; */
        if (message.orientation !== 0)
            writer.tag(6, WireType.Varint).int32(message.orientation);
        /* string description = 8; */
        if (message.description !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.description);
        /* google.protobuf.Timestamp created_at = 9; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 10; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* int32 sequence_no = 11; */
        if (message.sequenceNo !== 0)
            writer.tag(11, WireType.Varint).int32(message.sequenceNo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message template.v1.Template
 */
export const Template = new Template$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTemplate$Type extends MessageType<ListTemplate> {
    constructor() {
        super("template.v1.ListTemplate", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "preview_image_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "preset", kind: "enum", T: () => ["base.v1.Preset", Preset] },
            { no: 5, name: "orientation", kind: "enum", T: () => ["base.v1.Orientation", Orientation, "ORIENTATION_"] },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 8, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 9, name: "sequence_no", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListTemplate>): ListTemplate {
        const message = { id: "", name: "", previewImageId: "", preset: 0, orientation: 0, description: "", sequenceNo: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListTemplate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTemplate): ListTemplate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string preview_image_id */ 3:
                    message.previewImageId = reader.string();
                    break;
                case /* base.v1.Preset preset */ 4:
                    message.preset = reader.int32();
                    break;
                case /* base.v1.Orientation orientation */ 5:
                    message.orientation = reader.int32();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 7:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 8:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* int32 sequence_no */ 9:
                    message.sequenceNo = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTemplate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string preview_image_id = 3; */
        if (message.previewImageId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.previewImageId);
        /* base.v1.Preset preset = 4; */
        if (message.preset !== 0)
            writer.tag(4, WireType.Varint).int32(message.preset);
        /* base.v1.Orientation orientation = 5; */
        if (message.orientation !== 0)
            writer.tag(5, WireType.Varint).int32(message.orientation);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        /* google.protobuf.Timestamp created_at = 7; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 8; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* int32 sequence_no = 9; */
        if (message.sequenceNo !== 0)
            writer.tag(9, WireType.Varint).int32(message.sequenceNo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message template.v1.ListTemplate
 */
export const ListTemplate = new ListTemplate$Type();
