import {
  CreatePlanRequest,
  CreatePlanResponse,
  UpdatePlanRequest,
  UpdatePlanResponse,
  DeletePlanRequest,
  DeletePlanResponse,
  ListPlansRequest,
  ListPlansResponse,
  ListAllPlansRequest,
  ListAllPlansResponse,
  GetPlanRequest,
  GetPlanResponse,
  GetPlanFinalAmountRequest,
  GetPlanFinalAmountResponse,
  PurchasePlanRequest,
  PurchasePlanResponse,
  ProcessPurchasePlanRequest,
  ProcessPurchasePlanResponse,
  GetActiveSubscriptionRequest,
  GetActiveSubscriptionResponse,
  ManualPlanActivationRequest,
  ManualPlanActivationResponse,
  ListActiveSubscriptionsRequest,
  ListActiveSubscriptionsResponse,
} from "./plan_api";

//==================================//
//          Client Code             //
//==================================//

interface Rpc {
  request(
    service: string,
    method: string,
    contentType: "application/json" | "application/protobuf",
    data: object | Uint8Array
  ): Promise<object | Uint8Array>;
}

export interface PlanAPIClient {
  CreatePlan(request: CreatePlanRequest): Promise<CreatePlanResponse>;
  UpdatePlan(request: UpdatePlanRequest): Promise<UpdatePlanResponse>;
  DeletePlan(request: DeletePlanRequest): Promise<DeletePlanResponse>;
  ListPlans(request: ListPlansRequest): Promise<ListPlansResponse>;
  ListAllPlans(request: ListAllPlansRequest): Promise<ListAllPlansResponse>;
  GetPlan(request: GetPlanRequest): Promise<GetPlanResponse>;
  GetPlanFinalAmount(
    request: GetPlanFinalAmountRequest
  ): Promise<GetPlanFinalAmountResponse>;
  PurchasePlan(request: PurchasePlanRequest): Promise<PurchasePlanResponse>;
  ProcessPurchasePlan(
    request: ProcessPurchasePlanRequest
  ): Promise<ProcessPurchasePlanResponse>;
  GetActiveSubscription(
    request: GetActiveSubscriptionRequest
  ): Promise<GetActiveSubscriptionResponse>;
  ManualPlanActivation(
    request: ManualPlanActivationRequest
  ): Promise<ManualPlanActivationResponse>;
  ListActiveSubscriptions(
    request: ListActiveSubscriptionsRequest
  ): Promise<ListActiveSubscriptionsResponse>;
}

export class PlanAPIClientJSON implements PlanAPIClient {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreatePlan.bind(this);
    this.UpdatePlan.bind(this);
    this.DeletePlan.bind(this);
    this.ListPlans.bind(this);
    this.ListAllPlans.bind(this);
    this.GetPlan.bind(this);
    this.GetPlanFinalAmount.bind(this);
    this.PurchasePlan.bind(this);
    this.ProcessPurchasePlan.bind(this);
    this.GetActiveSubscription.bind(this);
    this.ManualPlanActivation.bind(this);
    this.ListActiveSubscriptions.bind(this);
  }
  CreatePlan(request: CreatePlanRequest): Promise<CreatePlanResponse> {
    const data = CreatePlanRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "CreatePlan",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      CreatePlanResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  UpdatePlan(request: UpdatePlanRequest): Promise<UpdatePlanResponse> {
    const data = UpdatePlanRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "UpdatePlan",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      UpdatePlanResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  DeletePlan(request: DeletePlanRequest): Promise<DeletePlanResponse> {
    const data = DeletePlanRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "DeletePlan",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      DeletePlanResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  ListPlans(request: ListPlansRequest): Promise<ListPlansResponse> {
    const data = ListPlansRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "ListPlans",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      ListPlansResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  ListAllPlans(request: ListAllPlansRequest): Promise<ListAllPlansResponse> {
    const data = ListAllPlansRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "ListAllPlans",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      ListAllPlansResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  GetPlan(request: GetPlanRequest): Promise<GetPlanResponse> {
    const data = GetPlanRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "GetPlan",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      GetPlanResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  GetPlanFinalAmount(
    request: GetPlanFinalAmountRequest
  ): Promise<GetPlanFinalAmountResponse> {
    const data = GetPlanFinalAmountRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "GetPlanFinalAmount",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      GetPlanFinalAmountResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }

  PurchasePlan(request: PurchasePlanRequest): Promise<PurchasePlanResponse> {
    const data = PurchasePlanRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "PurchasePlan",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      PurchasePlanResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  ProcessPurchasePlan(
    request: ProcessPurchasePlanRequest
  ): Promise<ProcessPurchasePlanResponse> {
    const data = ProcessPurchasePlanRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "ProcessPurchasePlan",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      ProcessPurchasePlanResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }

  GetActiveSubscription(
    request: GetActiveSubscriptionRequest
  ): Promise<GetActiveSubscriptionResponse> {
    const data = GetActiveSubscriptionRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "GetActiveSubscription",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      GetActiveSubscriptionResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }

  ManualPlanActivation(
    request: ManualPlanActivationRequest
  ): Promise<ManualPlanActivationResponse> {
    const data = ManualPlanActivationRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "ManualPlanActivation",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      ManualPlanActivationResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }

  ListActiveSubscriptions(
    request: ListActiveSubscriptionsRequest
  ): Promise<ListActiveSubscriptionsResponse> {
    const data = ListActiveSubscriptionsRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "ListActiveSubscriptions",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      ListActiveSubscriptionsResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }
}

export class PlanAPIClientProtobuf implements PlanAPIClient {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreatePlan.bind(this);
    this.UpdatePlan.bind(this);
    this.DeletePlan.bind(this);
    this.ListPlans.bind(this);
    this.ListAllPlans.bind(this);
    this.GetPlan.bind(this);
    this.GetPlanFinalAmount.bind(this);
    this.PurchasePlan.bind(this);
    this.ProcessPurchasePlan.bind(this);
    this.GetActiveSubscription.bind(this);
    this.ManualPlanActivation.bind(this);
    this.ListActiveSubscriptions.bind(this);
  }
  CreatePlan(request: CreatePlanRequest): Promise<CreatePlanResponse> {
    const data = CreatePlanRequest.toBinary(request);
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "CreatePlan",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      CreatePlanResponse.fromBinary(data as Uint8Array)
    );
  }

  UpdatePlan(request: UpdatePlanRequest): Promise<UpdatePlanResponse> {
    const data = UpdatePlanRequest.toBinary(request);
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "UpdatePlan",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      UpdatePlanResponse.fromBinary(data as Uint8Array)
    );
  }

  DeletePlan(request: DeletePlanRequest): Promise<DeletePlanResponse> {
    const data = DeletePlanRequest.toBinary(request);
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "DeletePlan",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      DeletePlanResponse.fromBinary(data as Uint8Array)
    );
  }

  ListPlans(request: ListPlansRequest): Promise<ListPlansResponse> {
    const data = ListPlansRequest.toBinary(request);
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "ListPlans",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      ListPlansResponse.fromBinary(data as Uint8Array)
    );
  }

  ListAllPlans(request: ListAllPlansRequest): Promise<ListAllPlansResponse> {
    const data = ListAllPlansRequest.toBinary(request);
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "ListAllPlans",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      ListAllPlansResponse.fromBinary(data as Uint8Array)
    );
  }

  GetPlan(request: GetPlanRequest): Promise<GetPlanResponse> {
    const data = GetPlanRequest.toBinary(request);
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "GetPlan",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      GetPlanResponse.fromBinary(data as Uint8Array)
    );
  }

  GetPlanFinalAmount(
    request: GetPlanFinalAmountRequest
  ): Promise<GetPlanFinalAmountResponse> {
    const data = GetPlanFinalAmountRequest.toBinary(request);
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "GetPlanFinalAmount",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      GetPlanFinalAmountResponse.fromBinary(data as Uint8Array)
    );
  }

  PurchasePlan(request: PurchasePlanRequest): Promise<PurchasePlanResponse> {
    const data = PurchasePlanRequest.toBinary(request);
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "PurchasePlan",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      PurchasePlanResponse.fromBinary(data as Uint8Array)
    );
  }

  ProcessPurchasePlan(
    request: ProcessPurchasePlanRequest
  ): Promise<ProcessPurchasePlanResponse> {
    const data = ProcessPurchasePlanRequest.toBinary(request);
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "ProcessPurchasePlan",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      ProcessPurchasePlanResponse.fromBinary(data as Uint8Array)
    );
  }

  GetActiveSubscription(
    request: GetActiveSubscriptionRequest
  ): Promise<GetActiveSubscriptionResponse> {
    const data = GetActiveSubscriptionRequest.toBinary(request);
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "GetActiveSubscription",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      GetActiveSubscriptionResponse.fromBinary(data as Uint8Array)
    );
  }

  ManualPlanActivation(
    request: ManualPlanActivationRequest
  ): Promise<ManualPlanActivationResponse> {
    const data = ManualPlanActivationRequest.toBinary(request);
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "ManualPlanActivation",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      ManualPlanActivationResponse.fromBinary(data as Uint8Array)
    );
  }

  ListActiveSubscriptions(
    request: ListActiveSubscriptionsRequest
  ): Promise<ListActiveSubscriptionsResponse> {
    const data = ListActiveSubscriptionsRequest.toBinary(request);
    const promise = this.rpc.request(
      "plan.v1.PlanAPI",
      "ListActiveSubscriptions",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      ListActiveSubscriptionsResponse.fromBinary(data as Uint8Array)
    );
  }
}
