import { useGlobalStateContext } from "hooks/useGlobalState";
import { ResourceType } from "proto/base/v1/resource";
import { User } from "proto/base/v1/user";
import { Subscription } from "proto/plan/v1/plan";
import { ReactNode } from "react";

export type PermissionEvalFn = (
  user?: User,
  hasActiveSubscription?: boolean,
  subscription?: Subscription
) => boolean;

type Props = {
  fn: PermissionEvalFn;
  children?: ReactNode;
};

export function EnsureResourcePermission({ fn, children }: Props) {
  const { data } = useGlobalStateContext();
  if (!data || !fn(data.user, data.hasActiveSubscription, data.subscription)) {
    return <></>;
  }

  return <>{children}</>;
}

export const doesUserHaveAccess = (
  resource: ResourceType
): PermissionEvalFn => {
  return (
    user?: User,
    hasActiveSubscription?: boolean,
    subscription?: Subscription
  ): boolean => {
    return hasActiveSubscription &&
      subscription?.credits.find(
        (c) =>
          c.resourceType === resource && (c.credits > 0 || c.credits === -1)
      )
      ? true
      : false;
  };
};
