import { Center, chakra, Flex, Text } from "@chakra-ui/react";
import SuperTokens from "supertokens-auth-react";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";
import popoverTheme from "Theme/PopoverTheme";

const appName = process.env.REACT_APP_NAME ?? "Reticulo";
const apiDomain = process.env.REACT_APP_API_DOMAIN ?? "http://localhost:9000";
const websiteDomain =
  process.env.REACT_APP_WEBSITE_DOMAIN ?? "http://localhost:3000";
const apiBasePath = process.env.REACT_APP_AUTH_API_BASEPATH ?? "/auth";
const websiteBasePath =
  process.env.REACT_APP_AUTH_API_WEBSITEBASEPATH ?? "/auth";
const sessionScope = process.env.REACT_APP_SESSION_SCOPE ?? ".localhost";

const primaryColor = popoverTheme.colors.brand["500"];

type PageType = "show-spam-banner" | "";

const overrideFn =
  (type?: PageType) =>
  ({ DefaultComponent, ...props }: any) => {
    return (
      <chakra.div w="100vw">
        <chakra.header bg={"brand.500"} w="full" roundedBottom={"lg"}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            mx="auto"
            h={["10vh", "7vh", "7vh"]}
            w="100vw"
          >
            <Text fontSize={["2xl", "3xl", "3xl"]} color="white" w="100vw">
              <Center>{appName}</Center>
            </Text>
          </Flex>
        </chakra.header>

        <DefaultComponent {...props} />
        {type === "show-spam-banner" && (
          <Center mt={-8}>
            <chakra.p>
              <Text fontWeight={"bold"} color="red.500" textAlign={"center"}>
                Please also check your spam folder!
              </Text>{" "}
              <Text
                fontWeight={"bold"}
                display={{
                  base: "block",
                  lg: "inline",
                }}
                textAlign={"center"}
              >
                You should receive an email from{" "}
              </Text>
              <Text
                color={"blue.500"}
                display={{
                  base: "block",
                  lg: "inline",
                }}
                textAlign={"center"}
              >
                admin@reticulo.in
              </Text>
            </chakra.p>
          </Center>
        )}
      </chakra.div>
    );
  };

export const initSupertokens = () => {
  SuperTokens.init({
    appInfo: {
      // learn more about this on https://supertokens.com/docs/emailpassword/appinfo
      appName,
      apiDomain,
      websiteDomain,
      apiBasePath,
      websiteBasePath,
    },
    recipeList: [
      EmailPassword.init({
        override: {
          components: {
            EmailPasswordSignIn_Override: overrideFn(),
            EmailPasswordResetPasswordEmail_Override:
              overrideFn("show-spam-banner"),
            EmailPasswordSignUp_Override: overrideFn(),
            EmailPasswordSubmitNewPassword_Override: overrideFn(),
          },
          emailVerification: {
            components: {
              EmailVerificationSendVerifyEmail_Override:
                overrideFn("show-spam-banner"),
              EmailVerificationVerifyEmailLinkClicked_Override: overrideFn(),
            },
          },
        },
        signInAndUpFeature: {
          signUpForm: {
            formFields: [
              {
                id: "first_name",
                label: "First Name",
                placeholder: "First name",
              },
              {
                id: "last_name",
                label: "Last Name",
                placeholder: "Last Name",
              },
            ],
          },
        },
        emailVerificationFeature: {
          mode: "OFF",
        },
        palette: {
          primary: primaryColor,
        },
        style: {
          superTokensBranding: {
            display: "none",
          },
        },
      }),
      Session.init({
        sessionScope: sessionScope,
      }),
    ],
  });
};
