// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "base/v1/media.proto" (package "base.v1", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../google/protobuf/timestamp";
/**
 * @generated from protobuf message base.v1.Media
 */
export interface Media {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string url = 2;
     */
    url: string;
    /**
     * @generated from protobuf field: string content_type = 3;
     */
    contentType: string;
    /**
     * @generated from protobuf field: string meta_data = 4;
     */
    metaData: string;
    /**
     * @generated from protobuf field: string file_name = 5;
     */
    fileName: string;
    /**
     * @generated from protobuf field: int64 file_size = 6;
     */
    fileSize: number;
    /**
     * @generated from protobuf field: base.v1.MediaType type = 7;
     */
    type: MediaType;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 8;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 9;
     */
    updatedAt?: Timestamp;
}
/**
 * @generated from protobuf message base.v1.UploadMedia
 */
export interface UploadMedia {
    /**
     * @generated from protobuf field: bytes data = 1;
     */
    data: Uint8Array;
    /**
     * @generated from protobuf field: string content_type = 2;
     */
    contentType: string;
    /**
     * @generated from protobuf field: string meta_data = 3;
     */
    metaData: string;
    /**
     * @generated from protobuf field: string file_name = 4;
     */
    fileName: string;
    /**
     * @generated from protobuf field: base.v1.MediaType type = 5;
     */
    type: MediaType;
}
/**
 * @generated from protobuf enum base.v1.MediaType
 */
export enum MediaType {
    /**
     * @generated from protobuf enum value: MEDIA_TYPE_INVALID = 0;
     */
    INVALID = 0,
    /**
     * @generated from protobuf enum value: MEDIA_TYPE_IMAGE = 1;
     */
    IMAGE = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class Media$Type extends MessageType<Media> {
    constructor() {
        super("base.v1.Media", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "content_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "meta_data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "file_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "file_size", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "type", kind: "enum", T: () => ["base.v1.MediaType", MediaType, "MEDIA_TYPE_"] },
            { no: 8, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 9, name: "updated_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<Media>): Media {
        const message = { id: "", url: "", contentType: "", metaData: "", fileName: "", fileSize: 0, type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Media>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Media): Media {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string url */ 2:
                    message.url = reader.string();
                    break;
                case /* string content_type */ 3:
                    message.contentType = reader.string();
                    break;
                case /* string meta_data */ 4:
                    message.metaData = reader.string();
                    break;
                case /* string file_name */ 5:
                    message.fileName = reader.string();
                    break;
                case /* int64 file_size */ 6:
                    message.fileSize = reader.int64().toNumber();
                    break;
                case /* base.v1.MediaType type */ 7:
                    message.type = reader.int32();
                    break;
                case /* google.protobuf.Timestamp created_at */ 8:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 9:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Media, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string url = 2; */
        if (message.url !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.url);
        /* string content_type = 3; */
        if (message.contentType !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.contentType);
        /* string meta_data = 4; */
        if (message.metaData !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.metaData);
        /* string file_name = 5; */
        if (message.fileName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.fileName);
        /* int64 file_size = 6; */
        if (message.fileSize !== 0)
            writer.tag(6, WireType.Varint).int64(message.fileSize);
        /* base.v1.MediaType type = 7; */
        if (message.type !== 0)
            writer.tag(7, WireType.Varint).int32(message.type);
        /* google.protobuf.Timestamp created_at = 8; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 9; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message base.v1.Media
 */
export const Media = new Media$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadMedia$Type extends MessageType<UploadMedia> {
    constructor() {
        super("base.v1.UploadMedia", [
            { no: 1, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "content_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "meta_data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "file_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "type", kind: "enum", T: () => ["base.v1.MediaType", MediaType, "MEDIA_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<UploadMedia>): UploadMedia {
        const message = { data: new Uint8Array(0), contentType: "", metaData: "", fileName: "", type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UploadMedia>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadMedia): UploadMedia {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes data */ 1:
                    message.data = reader.bytes();
                    break;
                case /* string content_type */ 2:
                    message.contentType = reader.string();
                    break;
                case /* string meta_data */ 3:
                    message.metaData = reader.string();
                    break;
                case /* string file_name */ 4:
                    message.fileName = reader.string();
                    break;
                case /* base.v1.MediaType type */ 5:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UploadMedia, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes data = 1; */
        if (message.data.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.data);
        /* string content_type = 2; */
        if (message.contentType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.contentType);
        /* string meta_data = 3; */
        if (message.metaData !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.metaData);
        /* string file_name = 4; */
        if (message.fileName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.fileName);
        /* base.v1.MediaType type = 5; */
        if (message.type !== 0)
            writer.tag(5, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message base.v1.UploadMedia
 */
export const UploadMedia = new UploadMedia$Type();
