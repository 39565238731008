import { chakra, Container } from "@chakra-ui/react";
import { Loading } from "components";
import { StateProvider } from "hooks/provider";
import { doesUserHaveAccess, EnsureResourcePermission } from "permissions";
import { ResourceType } from "proto/base/v1/resource";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { EmailPasswordAuth } from "supertokens-auth-react/recipe/emailpassword";
import { MainLayout } from "./layout";

const BoardsPage = React.lazy(
  () => import("./whiteboard/board/pages/BoardsPage")
);
const BoardPage = React.lazy(
  () => import("./whiteboard/board/pages/BoardPage")
);
const NewBoardPage = React.lazy(
  () => import("./whiteboard/board/pages/NewBoardPage")
);
const MainDashboardPage = React.lazy(
  () => import("./dashboard/pages/MainDashboardPage")
);
const RoomsPage = React.lazy(() => import("./room/pages/RoomsPage"));
const RoomPage = React.lazy(() => import("./room/pages/RoomPage"));
const PlansPage = React.lazy(() => import("./plan/pages/PlansPage"));
const ProfilePage = React.lazy(() => import("./profile/pages/ProfilePage"));
const NotificationPage = React.lazy(
  () => import("./notification/pages/NotificationsPage")
);

const LoadingFallback = () => {
  return (
    <Container>
      <Loading text="" />
    </Container>
  );
};

export const Main = () => {
  return (
    <EmailPasswordAuth>
      <StateProvider>
        <MainLayout>
          <chakra.div>
            <Routes>
              <Route
                index
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <MainDashboardPage />
                  </React.Suspense>
                }
              />

              <Route
                path="/boards"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <BoardsPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/board/:id"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <BoardPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/new-board"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <NewBoardPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/rooms"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <EnsureResourcePermission
                      fn={doesUserHaveAccess(ResourceType.ROOM)}
                    >
                      <RoomsPage fetchInvited={false} />
                    </EnsureResourcePermission>
                  </React.Suspense>
                }
              />
              <Route
                path="/invited-rooms"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <RoomsPage fetchInvited={true} />
                  </React.Suspense>
                }
              />
              <Route
                path="/room/:id"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <EnsureResourcePermission
                      fn={doesUserHaveAccess(ResourceType.ROOM)}
                    >
                      <RoomPage />
                    </EnsureResourcePermission>
                  </React.Suspense>
                }
              />
              <Route
                path="/plans"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <PlansPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/profile"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <ProfilePage />
                  </React.Suspense>
                }
              />
              <Route
                path="/notifications"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <NotificationPage />
                  </React.Suspense>
                }
              />
            </Routes>
          </chakra.div>
        </MainLayout>
      </StateProvider>
    </EmailPasswordAuth>
  );
};
