// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "base/v1/access.proto" (package "base.v1", syntax proto3)
// tslint:disable
/**
 * @generated from protobuf enum base.v1.AccessType
 */
export enum AccessType {
    /**
     * @generated from protobuf enum value: ACCESS_TYPE_INVALID = 0;
     */
    INVALID = 0,
    /**
     * @generated from protobuf enum value: ACCESS_TYPE_OWNER = 1;
     */
    OWNER = 1,
    /**
     * @generated from protobuf enum value: ACCESS_TYPE_EDITOR = 2;
     */
    EDITOR = 2,
    /**
     * @generated from protobuf enum value: ACCESS_TYPE_VIEWER = 3;
     */
    VIEWER = 3
}
