// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "room/v1/room.proto" (package "room.v1", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { AccessType } from "../../base/v1/access";
import { User } from "../../base/v1/user";
/**
 * @generated from protobuf message room.v1.Room
 */
export interface Room {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string color = 3;
     */
    color: string;
    /**
     * @generated from protobuf field: repeated room.v1.RoomUser users = 4;
     */
    users: RoomUser[];
    /**
     * @generated from protobuf field: string is_shared_to_me = 5;
     */
    isSharedToMe: string;
    /**
     * @generated from protobuf field: base.v1.User owner = 6;
     */
    owner?: User;
}
/**
 * @generated from protobuf message room.v1.RoomUser
 */
export interface RoomUser {
    /**
     * @generated from protobuf field: base.v1.User user = 1;
     */
    user?: User;
    /**
     * @generated from protobuf field: base.v1.AccessType access_type = 2;
     */
    accessType: AccessType;
}
// @generated message type with reflection information, may provide speed optimized methods
class Room$Type extends MessageType<Room> {
    constructor() {
        super("room.v1.Room", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "color", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RoomUser },
            { no: 5, name: "is_shared_to_me", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "owner", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<Room>): Room {
        const message = { id: "", name: "", color: "", users: [], isSharedToMe: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Room>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Room): Room {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string color */ 3:
                    message.color = reader.string();
                    break;
                case /* repeated room.v1.RoomUser users */ 4:
                    message.users.push(RoomUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string is_shared_to_me */ 5:
                    message.isSharedToMe = reader.string();
                    break;
                case /* base.v1.User owner */ 6:
                    message.owner = User.internalBinaryRead(reader, reader.uint32(), options, message.owner);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Room, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string color = 3; */
        if (message.color !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.color);
        /* repeated room.v1.RoomUser users = 4; */
        for (let i = 0; i < message.users.length; i++)
            RoomUser.internalBinaryWrite(message.users[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string is_shared_to_me = 5; */
        if (message.isSharedToMe !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.isSharedToMe);
        /* base.v1.User owner = 6; */
        if (message.owner)
            User.internalBinaryWrite(message.owner, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.Room
 */
export const Room = new Room$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomUser$Type extends MessageType<RoomUser> {
    constructor() {
        super("room.v1.RoomUser", [
            { no: 1, name: "user", kind: "message", T: () => User },
            { no: 2, name: "access_type", kind: "enum", T: () => ["base.v1.AccessType", AccessType, "ACCESS_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<RoomUser>): RoomUser {
        const message = { accessType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RoomUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomUser): RoomUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* base.v1.AccessType access_type */ 2:
                    message.accessType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* base.v1.AccessType access_type = 2; */
        if (message.accessType !== 0)
            writer.tag(2, WireType.Varint).int32(message.accessType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.RoomUser
 */
export const RoomUser = new RoomUser$Type();
