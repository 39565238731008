// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "plan/v1/plan_api.proto" (package "plan.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ActiveSubscriptionWithPayment } from "./plan";
import { Subscription } from "./plan";
import { PaymentStatus } from "../../base/v1/payment";
import { Plan } from "./plan";
import { SortDirection } from "../../base/v1/sort";
import { Credit } from "./plan";
/**
 * @generated from protobuf message plan.v1.CreatePlanRequest
 */
export interface CreatePlanRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: double price = 2;
     */
    price: number;
    /**
     * @generated from protobuf field: double actual_price = 3;
     */
    actualPrice: number;
    /**
     * @generated from protobuf field: int32 interval = 4;
     */
    interval: number;
    /**
     * @generated from protobuf field: int32 sequence_no = 5;
     */
    sequenceNo: number;
    /**
     * @generated from protobuf field: string intended_for = 6;
     */
    intendedFor: string;
    /**
     * @generated from protobuf field: string interval_string = 7;
     */
    intervalString: string;
    /**
     * @generated from protobuf field: repeated plan.v1.Credit credits = 8;
     */
    credits: Credit[];
    /**
     * @generated from protobuf field: int32 primacy = 9;
     */
    primacy: number;
}
/**
 * @generated from protobuf message plan.v1.CreatePlanResponse
 */
export interface CreatePlanResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message plan.v1.UpdatePlanRequest
 */
export interface UpdatePlanRequest {
    /**
     * @generated from protobuf field: string plan_id = 1;
     */
    planId: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: double price = 3;
     */
    price: number;
    /**
     * @generated from protobuf field: double actual_price = 4;
     */
    actualPrice: number;
    /**
     * @generated from protobuf field: int32 interval = 5;
     */
    interval: number;
    /**
     * @generated from protobuf field: int32 sequence_no = 6;
     */
    sequenceNo: number;
    /**
     * @generated from protobuf field: string intended_for = 7;
     */
    intendedFor: string;
    /**
     * @generated from protobuf field: string interval_string = 8;
     */
    intervalString: string;
    /**
     * @generated from protobuf field: repeated plan.v1.Credit credits = 9;
     */
    credits: Credit[];
    /**
     * @generated from protobuf field: int32 primacy = 10;
     */
    primacy: number;
}
/**
 * @generated from protobuf message plan.v1.UpdatePlanResponse
 */
export interface UpdatePlanResponse {
}
/**
 * @generated from protobuf message plan.v1.ListPlansRequest
 */
export interface ListPlansRequest {
    /**
     * which page to list
     *
     * @generated from protobuf field: int32 page = 1;
     */
    page: number;
    /**
     * size of the page
     *
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
    /**
     * sort direction
     *
     * @generated from protobuf field: base.v1.SortDirection sort_direction = 3;
     */
    sortDirection: SortDirection;
}
/**
 * @generated from protobuf message plan.v1.ListPlansResponse
 */
export interface ListPlansResponse {
    /**
     * @generated from protobuf field: repeated plan.v1.Plan plans = 1;
     */
    plans: Plan[];
}
/**
 * @generated from protobuf message plan.v1.ListAllPlansRequest
 */
export interface ListAllPlansRequest {
    /**
     * which page to list
     *
     * @generated from protobuf field: int32 page = 1;
     */
    page: number;
    /**
     * size of the page
     *
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
    /**
     * sort direction
     *
     * @generated from protobuf field: base.v1.SortDirection sort_direction = 3;
     */
    sortDirection: SortDirection;
}
/**
 * @generated from protobuf message plan.v1.ListAllPlansResponse
 */
export interface ListAllPlansResponse {
    /**
     * @generated from protobuf field: repeated plan.v1.Plan plans = 1;
     */
    plans: Plan[];
}
/**
 * @generated from protobuf message plan.v1.GetPlanRequest
 */
export interface GetPlanRequest {
    /**
     * @generated from protobuf field: string plan_id = 1;
     */
    planId: string;
}
/**
 * @generated from protobuf message plan.v1.GetPlanResponse
 */
export interface GetPlanResponse {
    /**
     * @generated from protobuf field: plan.v1.Plan plan = 1;
     */
    plan?: Plan;
}
/**
 * @generated from protobuf message plan.v1.GetPlanFinalAmountRequest
 */
export interface GetPlanFinalAmountRequest {
    /**
     * @generated from protobuf field: string plan_id = 1;
     */
    planId: string;
    /**
     * @generated from protobuf field: string coupon = 2;
     */
    coupon: string;
}
/**
 * @generated from protobuf message plan.v1.GetPlanFinalAmountResponse
 */
export interface GetPlanFinalAmountResponse {
    /**
     * @generated from protobuf field: double amount = 1;
     */
    amount: number;
}
/**
 * @generated from protobuf message plan.v1.PurchasePlanRequest
 */
export interface PurchasePlanRequest {
    /**
     * @generated from protobuf field: string plan_id = 1;
     */
    planId: string;
    /**
     * @generated from protobuf field: string coupon = 2;
     */
    coupon: string;
}
/**
 * @generated from protobuf message plan.v1.PurchasePlanResponse
 */
export interface PurchasePlanResponse {
    /**
     * @generated from protobuf field: string payment_id = 1;
     */
    paymentId: string;
    /**
     * @generated from protobuf field: double amount = 2;
     */
    amount: number;
    /**
     * @generated from protobuf field: string razorpay_order_id = 3;
     */
    razorpayOrderId: string;
}
/**
 * @generated from protobuf message plan.v1.ProcessPurchasePlanRequest
 */
export interface ProcessPurchasePlanRequest {
    /**
     * @generated from protobuf field: string payment_id = 1;
     */
    paymentId: string;
}
/**
 * @generated from protobuf message plan.v1.ProcessPurchasePlanResponse
 */
export interface ProcessPurchasePlanResponse {
    /**
     * @generated from protobuf field: base.v1.PaymentStatus status = 1;
     */
    status: PaymentStatus;
}
/**
 * @generated from protobuf message plan.v1.DeletePlanRequest
 */
export interface DeletePlanRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message plan.v1.DeletePlanResponse
 */
export interface DeletePlanResponse {
}
/**
 * @generated from protobuf message plan.v1.GetActiveSubscriptionRequest
 */
export interface GetActiveSubscriptionRequest {
}
/**
 * @generated from protobuf message plan.v1.GetActiveSubscriptionResponse
 */
export interface GetActiveSubscriptionResponse {
    /**
     * @generated from protobuf field: bool has_active_subscription = 1;
     */
    hasActiveSubscription: boolean;
    /**
     * @generated from protobuf field: plan.v1.Subscription subscription = 2;
     */
    subscription?: Subscription;
}
/**
 * @generated from protobuf message plan.v1.ManualPlanActivationRequest
 */
export interface ManualPlanActivationRequest {
    /**
     * @generated from protobuf field: string plan_id = 1;
     */
    planId: string;
    /**
     * @generated from protobuf field: string user_id = 2;
     */
    userId: string;
    /**
     * @generated from protobuf field: double paid_amount = 3;
     */
    paidAmount: number;
    /**
     * @generated from protobuf field: string coupon = 4;
     */
    coupon: string;
}
/**
 * @generated from protobuf message plan.v1.ManualPlanActivationResponse
 */
export interface ManualPlanActivationResponse {
}
/**
 * @generated from protobuf message plan.v1.ListActiveSubscriptionsRequest
 */
export interface ListActiveSubscriptionsRequest {
}
/**
 * @generated from protobuf message plan.v1.ListActiveSubscriptionsResponse
 */
export interface ListActiveSubscriptionsResponse {
    /**
     * @generated from protobuf field: repeated plan.v1.ActiveSubscriptionWithPayment active_subscriptions = 1;
     */
    activeSubscriptions: ActiveSubscriptionWithPayment[];
}
// @generated message type with reflection information, may provide speed optimized methods
class CreatePlanRequest$Type extends MessageType<CreatePlanRequest> {
    constructor() {
        super("plan.v1.CreatePlanRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "price", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "actual_price", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "interval", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "sequence_no", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "intended_for", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "interval_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "credits", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Credit },
            { no: 9, name: "primacy", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CreatePlanRequest>): CreatePlanRequest {
        const message = { name: "", price: 0, actualPrice: 0, interval: 0, sequenceNo: 0, intendedFor: "", intervalString: "", credits: [], primacy: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreatePlanRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreatePlanRequest): CreatePlanRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* double price */ 2:
                    message.price = reader.double();
                    break;
                case /* double actual_price */ 3:
                    message.actualPrice = reader.double();
                    break;
                case /* int32 interval */ 4:
                    message.interval = reader.int32();
                    break;
                case /* int32 sequence_no */ 5:
                    message.sequenceNo = reader.int32();
                    break;
                case /* string intended_for */ 6:
                    message.intendedFor = reader.string();
                    break;
                case /* string interval_string */ 7:
                    message.intervalString = reader.string();
                    break;
                case /* repeated plan.v1.Credit credits */ 8:
                    message.credits.push(Credit.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 primacy */ 9:
                    message.primacy = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreatePlanRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* double price = 2; */
        if (message.price !== 0)
            writer.tag(2, WireType.Bit64).double(message.price);
        /* double actual_price = 3; */
        if (message.actualPrice !== 0)
            writer.tag(3, WireType.Bit64).double(message.actualPrice);
        /* int32 interval = 4; */
        if (message.interval !== 0)
            writer.tag(4, WireType.Varint).int32(message.interval);
        /* int32 sequence_no = 5; */
        if (message.sequenceNo !== 0)
            writer.tag(5, WireType.Varint).int32(message.sequenceNo);
        /* string intended_for = 6; */
        if (message.intendedFor !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.intendedFor);
        /* string interval_string = 7; */
        if (message.intervalString !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.intervalString);
        /* repeated plan.v1.Credit credits = 8; */
        for (let i = 0; i < message.credits.length; i++)
            Credit.internalBinaryWrite(message.credits[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* int32 primacy = 9; */
        if (message.primacy !== 0)
            writer.tag(9, WireType.Varint).int32(message.primacy);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.CreatePlanRequest
 */
export const CreatePlanRequest = new CreatePlanRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreatePlanResponse$Type extends MessageType<CreatePlanResponse> {
    constructor() {
        super("plan.v1.CreatePlanResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreatePlanResponse>): CreatePlanResponse {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreatePlanResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreatePlanResponse): CreatePlanResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreatePlanResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.CreatePlanResponse
 */
export const CreatePlanResponse = new CreatePlanResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePlanRequest$Type extends MessageType<UpdatePlanRequest> {
    constructor() {
        super("plan.v1.UpdatePlanRequest", [
            { no: 1, name: "plan_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "price", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "actual_price", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "interval", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "sequence_no", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "intended_for", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "interval_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "credits", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Credit },
            { no: 10, name: "primacy", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<UpdatePlanRequest>): UpdatePlanRequest {
        const message = { planId: "", name: "", price: 0, actualPrice: 0, interval: 0, sequenceNo: 0, intendedFor: "", intervalString: "", credits: [], primacy: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdatePlanRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePlanRequest): UpdatePlanRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string plan_id */ 1:
                    message.planId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* double price */ 3:
                    message.price = reader.double();
                    break;
                case /* double actual_price */ 4:
                    message.actualPrice = reader.double();
                    break;
                case /* int32 interval */ 5:
                    message.interval = reader.int32();
                    break;
                case /* int32 sequence_no */ 6:
                    message.sequenceNo = reader.int32();
                    break;
                case /* string intended_for */ 7:
                    message.intendedFor = reader.string();
                    break;
                case /* string interval_string */ 8:
                    message.intervalString = reader.string();
                    break;
                case /* repeated plan.v1.Credit credits */ 9:
                    message.credits.push(Credit.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 primacy */ 10:
                    message.primacy = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdatePlanRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string plan_id = 1; */
        if (message.planId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.planId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* double price = 3; */
        if (message.price !== 0)
            writer.tag(3, WireType.Bit64).double(message.price);
        /* double actual_price = 4; */
        if (message.actualPrice !== 0)
            writer.tag(4, WireType.Bit64).double(message.actualPrice);
        /* int32 interval = 5; */
        if (message.interval !== 0)
            writer.tag(5, WireType.Varint).int32(message.interval);
        /* int32 sequence_no = 6; */
        if (message.sequenceNo !== 0)
            writer.tag(6, WireType.Varint).int32(message.sequenceNo);
        /* string intended_for = 7; */
        if (message.intendedFor !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.intendedFor);
        /* string interval_string = 8; */
        if (message.intervalString !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.intervalString);
        /* repeated plan.v1.Credit credits = 9; */
        for (let i = 0; i < message.credits.length; i++)
            Credit.internalBinaryWrite(message.credits[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* int32 primacy = 10; */
        if (message.primacy !== 0)
            writer.tag(10, WireType.Varint).int32(message.primacy);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.UpdatePlanRequest
 */
export const UpdatePlanRequest = new UpdatePlanRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePlanResponse$Type extends MessageType<UpdatePlanResponse> {
    constructor() {
        super("plan.v1.UpdatePlanResponse", []);
    }
    create(value?: PartialMessage<UpdatePlanResponse>): UpdatePlanResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdatePlanResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePlanResponse): UpdatePlanResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdatePlanResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.UpdatePlanResponse
 */
export const UpdatePlanResponse = new UpdatePlanResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPlansRequest$Type extends MessageType<ListPlansRequest> {
    constructor() {
        super("plan.v1.ListPlansRequest", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "sort_direction", kind: "enum", T: () => ["base.v1.SortDirection", SortDirection, "SORT_DIRECTION_"] }
        ]);
    }
    create(value?: PartialMessage<ListPlansRequest>): ListPlansRequest {
        const message = { page: 0, pageSize: 0, sortDirection: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListPlansRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPlansRequest): ListPlansRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 1:
                    message.page = reader.int32();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* base.v1.SortDirection sort_direction */ 3:
                    message.sortDirection = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPlansRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int32(message.page);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* base.v1.SortDirection sort_direction = 3; */
        if (message.sortDirection !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortDirection);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.ListPlansRequest
 */
export const ListPlansRequest = new ListPlansRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPlansResponse$Type extends MessageType<ListPlansResponse> {
    constructor() {
        super("plan.v1.ListPlansResponse", [
            { no: 1, name: "plans", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Plan }
        ]);
    }
    create(value?: PartialMessage<ListPlansResponse>): ListPlansResponse {
        const message = { plans: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListPlansResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPlansResponse): ListPlansResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated plan.v1.Plan plans */ 1:
                    message.plans.push(Plan.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPlansResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated plan.v1.Plan plans = 1; */
        for (let i = 0; i < message.plans.length; i++)
            Plan.internalBinaryWrite(message.plans[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.ListPlansResponse
 */
export const ListPlansResponse = new ListPlansResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAllPlansRequest$Type extends MessageType<ListAllPlansRequest> {
    constructor() {
        super("plan.v1.ListAllPlansRequest", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "sort_direction", kind: "enum", T: () => ["base.v1.SortDirection", SortDirection, "SORT_DIRECTION_"] }
        ]);
    }
    create(value?: PartialMessage<ListAllPlansRequest>): ListAllPlansRequest {
        const message = { page: 0, pageSize: 0, sortDirection: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListAllPlansRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAllPlansRequest): ListAllPlansRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 1:
                    message.page = reader.int32();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* base.v1.SortDirection sort_direction */ 3:
                    message.sortDirection = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAllPlansRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int32(message.page);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* base.v1.SortDirection sort_direction = 3; */
        if (message.sortDirection !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortDirection);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.ListAllPlansRequest
 */
export const ListAllPlansRequest = new ListAllPlansRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAllPlansResponse$Type extends MessageType<ListAllPlansResponse> {
    constructor() {
        super("plan.v1.ListAllPlansResponse", [
            { no: 1, name: "plans", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Plan }
        ]);
    }
    create(value?: PartialMessage<ListAllPlansResponse>): ListAllPlansResponse {
        const message = { plans: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListAllPlansResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAllPlansResponse): ListAllPlansResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated plan.v1.Plan plans */ 1:
                    message.plans.push(Plan.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAllPlansResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated plan.v1.Plan plans = 1; */
        for (let i = 0; i < message.plans.length; i++)
            Plan.internalBinaryWrite(message.plans[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.ListAllPlansResponse
 */
export const ListAllPlansResponse = new ListAllPlansResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPlanRequest$Type extends MessageType<GetPlanRequest> {
    constructor() {
        super("plan.v1.GetPlanRequest", [
            { no: 1, name: "plan_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPlanRequest>): GetPlanRequest {
        const message = { planId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPlanRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPlanRequest): GetPlanRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string plan_id */ 1:
                    message.planId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPlanRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string plan_id = 1; */
        if (message.planId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.planId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.GetPlanRequest
 */
export const GetPlanRequest = new GetPlanRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPlanResponse$Type extends MessageType<GetPlanResponse> {
    constructor() {
        super("plan.v1.GetPlanResponse", [
            { no: 1, name: "plan", kind: "message", T: () => Plan }
        ]);
    }
    create(value?: PartialMessage<GetPlanResponse>): GetPlanResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPlanResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPlanResponse): GetPlanResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* plan.v1.Plan plan */ 1:
                    message.plan = Plan.internalBinaryRead(reader, reader.uint32(), options, message.plan);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPlanResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* plan.v1.Plan plan = 1; */
        if (message.plan)
            Plan.internalBinaryWrite(message.plan, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.GetPlanResponse
 */
export const GetPlanResponse = new GetPlanResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPlanFinalAmountRequest$Type extends MessageType<GetPlanFinalAmountRequest> {
    constructor() {
        super("plan.v1.GetPlanFinalAmountRequest", [
            { no: 1, name: "plan_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "coupon", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPlanFinalAmountRequest>): GetPlanFinalAmountRequest {
        const message = { planId: "", coupon: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPlanFinalAmountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPlanFinalAmountRequest): GetPlanFinalAmountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string plan_id */ 1:
                    message.planId = reader.string();
                    break;
                case /* string coupon */ 2:
                    message.coupon = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPlanFinalAmountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string plan_id = 1; */
        if (message.planId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.planId);
        /* string coupon = 2; */
        if (message.coupon !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.coupon);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.GetPlanFinalAmountRequest
 */
export const GetPlanFinalAmountRequest = new GetPlanFinalAmountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPlanFinalAmountResponse$Type extends MessageType<GetPlanFinalAmountResponse> {
    constructor() {
        super("plan.v1.GetPlanFinalAmountResponse", [
            { no: 1, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<GetPlanFinalAmountResponse>): GetPlanFinalAmountResponse {
        const message = { amount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPlanFinalAmountResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPlanFinalAmountResponse): GetPlanFinalAmountResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double amount */ 1:
                    message.amount = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPlanFinalAmountResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double amount = 1; */
        if (message.amount !== 0)
            writer.tag(1, WireType.Bit64).double(message.amount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.GetPlanFinalAmountResponse
 */
export const GetPlanFinalAmountResponse = new GetPlanFinalAmountResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PurchasePlanRequest$Type extends MessageType<PurchasePlanRequest> {
    constructor() {
        super("plan.v1.PurchasePlanRequest", [
            { no: 1, name: "plan_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "coupon", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PurchasePlanRequest>): PurchasePlanRequest {
        const message = { planId: "", coupon: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PurchasePlanRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PurchasePlanRequest): PurchasePlanRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string plan_id */ 1:
                    message.planId = reader.string();
                    break;
                case /* string coupon */ 2:
                    message.coupon = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PurchasePlanRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string plan_id = 1; */
        if (message.planId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.planId);
        /* string coupon = 2; */
        if (message.coupon !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.coupon);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.PurchasePlanRequest
 */
export const PurchasePlanRequest = new PurchasePlanRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PurchasePlanResponse$Type extends MessageType<PurchasePlanResponse> {
    constructor() {
        super("plan.v1.PurchasePlanResponse", [
            { no: 1, name: "payment_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "razorpay_order_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PurchasePlanResponse>): PurchasePlanResponse {
        const message = { paymentId: "", amount: 0, razorpayOrderId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PurchasePlanResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PurchasePlanResponse): PurchasePlanResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string payment_id */ 1:
                    message.paymentId = reader.string();
                    break;
                case /* double amount */ 2:
                    message.amount = reader.double();
                    break;
                case /* string razorpay_order_id */ 3:
                    message.razorpayOrderId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PurchasePlanResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string payment_id = 1; */
        if (message.paymentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.paymentId);
        /* double amount = 2; */
        if (message.amount !== 0)
            writer.tag(2, WireType.Bit64).double(message.amount);
        /* string razorpay_order_id = 3; */
        if (message.razorpayOrderId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.razorpayOrderId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.PurchasePlanResponse
 */
export const PurchasePlanResponse = new PurchasePlanResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessPurchasePlanRequest$Type extends MessageType<ProcessPurchasePlanRequest> {
    constructor() {
        super("plan.v1.ProcessPurchasePlanRequest", [
            { no: 1, name: "payment_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProcessPurchasePlanRequest>): ProcessPurchasePlanRequest {
        const message = { paymentId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProcessPurchasePlanRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessPurchasePlanRequest): ProcessPurchasePlanRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string payment_id */ 1:
                    message.paymentId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessPurchasePlanRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string payment_id = 1; */
        if (message.paymentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.paymentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.ProcessPurchasePlanRequest
 */
export const ProcessPurchasePlanRequest = new ProcessPurchasePlanRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessPurchasePlanResponse$Type extends MessageType<ProcessPurchasePlanResponse> {
    constructor() {
        super("plan.v1.ProcessPurchasePlanResponse", [
            { no: 1, name: "status", kind: "enum", T: () => ["base.v1.PaymentStatus", PaymentStatus, "PAYMENT_STATUS_"] }
        ]);
    }
    create(value?: PartialMessage<ProcessPurchasePlanResponse>): ProcessPurchasePlanResponse {
        const message = { status: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProcessPurchasePlanResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessPurchasePlanResponse): ProcessPurchasePlanResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.v1.PaymentStatus status */ 1:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessPurchasePlanResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.v1.PaymentStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.ProcessPurchasePlanResponse
 */
export const ProcessPurchasePlanResponse = new ProcessPurchasePlanResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeletePlanRequest$Type extends MessageType<DeletePlanRequest> {
    constructor() {
        super("plan.v1.DeletePlanRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeletePlanRequest>): DeletePlanRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeletePlanRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeletePlanRequest): DeletePlanRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeletePlanRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.DeletePlanRequest
 */
export const DeletePlanRequest = new DeletePlanRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeletePlanResponse$Type extends MessageType<DeletePlanResponse> {
    constructor() {
        super("plan.v1.DeletePlanResponse", []);
    }
    create(value?: PartialMessage<DeletePlanResponse>): DeletePlanResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeletePlanResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeletePlanResponse): DeletePlanResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeletePlanResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.DeletePlanResponse
 */
export const DeletePlanResponse = new DeletePlanResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActiveSubscriptionRequest$Type extends MessageType<GetActiveSubscriptionRequest> {
    constructor() {
        super("plan.v1.GetActiveSubscriptionRequest", []);
    }
    create(value?: PartialMessage<GetActiveSubscriptionRequest>): GetActiveSubscriptionRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetActiveSubscriptionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetActiveSubscriptionRequest): GetActiveSubscriptionRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetActiveSubscriptionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.GetActiveSubscriptionRequest
 */
export const GetActiveSubscriptionRequest = new GetActiveSubscriptionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActiveSubscriptionResponse$Type extends MessageType<GetActiveSubscriptionResponse> {
    constructor() {
        super("plan.v1.GetActiveSubscriptionResponse", [
            { no: 1, name: "has_active_subscription", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "subscription", kind: "message", T: () => Subscription }
        ]);
    }
    create(value?: PartialMessage<GetActiveSubscriptionResponse>): GetActiveSubscriptionResponse {
        const message = { hasActiveSubscription: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetActiveSubscriptionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetActiveSubscriptionResponse): GetActiveSubscriptionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool has_active_subscription */ 1:
                    message.hasActiveSubscription = reader.bool();
                    break;
                case /* plan.v1.Subscription subscription */ 2:
                    message.subscription = Subscription.internalBinaryRead(reader, reader.uint32(), options, message.subscription);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetActiveSubscriptionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool has_active_subscription = 1; */
        if (message.hasActiveSubscription !== false)
            writer.tag(1, WireType.Varint).bool(message.hasActiveSubscription);
        /* plan.v1.Subscription subscription = 2; */
        if (message.subscription)
            Subscription.internalBinaryWrite(message.subscription, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.GetActiveSubscriptionResponse
 */
export const GetActiveSubscriptionResponse = new GetActiveSubscriptionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ManualPlanActivationRequest$Type extends MessageType<ManualPlanActivationRequest> {
    constructor() {
        super("plan.v1.ManualPlanActivationRequest", [
            { no: 1, name: "plan_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "paid_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "coupon", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ManualPlanActivationRequest>): ManualPlanActivationRequest {
        const message = { planId: "", userId: "", paidAmount: 0, coupon: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ManualPlanActivationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ManualPlanActivationRequest): ManualPlanActivationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string plan_id */ 1:
                    message.planId = reader.string();
                    break;
                case /* string user_id */ 2:
                    message.userId = reader.string();
                    break;
                case /* double paid_amount */ 3:
                    message.paidAmount = reader.double();
                    break;
                case /* string coupon */ 4:
                    message.coupon = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ManualPlanActivationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string plan_id = 1; */
        if (message.planId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.planId);
        /* string user_id = 2; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        /* double paid_amount = 3; */
        if (message.paidAmount !== 0)
            writer.tag(3, WireType.Bit64).double(message.paidAmount);
        /* string coupon = 4; */
        if (message.coupon !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.coupon);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.ManualPlanActivationRequest
 */
export const ManualPlanActivationRequest = new ManualPlanActivationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ManualPlanActivationResponse$Type extends MessageType<ManualPlanActivationResponse> {
    constructor() {
        super("plan.v1.ManualPlanActivationResponse", []);
    }
    create(value?: PartialMessage<ManualPlanActivationResponse>): ManualPlanActivationResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ManualPlanActivationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ManualPlanActivationResponse): ManualPlanActivationResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ManualPlanActivationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.ManualPlanActivationResponse
 */
export const ManualPlanActivationResponse = new ManualPlanActivationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListActiveSubscriptionsRequest$Type extends MessageType<ListActiveSubscriptionsRequest> {
    constructor() {
        super("plan.v1.ListActiveSubscriptionsRequest", []);
    }
    create(value?: PartialMessage<ListActiveSubscriptionsRequest>): ListActiveSubscriptionsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListActiveSubscriptionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListActiveSubscriptionsRequest): ListActiveSubscriptionsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListActiveSubscriptionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.ListActiveSubscriptionsRequest
 */
export const ListActiveSubscriptionsRequest = new ListActiveSubscriptionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListActiveSubscriptionsResponse$Type extends MessageType<ListActiveSubscriptionsResponse> {
    constructor() {
        super("plan.v1.ListActiveSubscriptionsResponse", [
            { no: 1, name: "active_subscriptions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ActiveSubscriptionWithPayment }
        ]);
    }
    create(value?: PartialMessage<ListActiveSubscriptionsResponse>): ListActiveSubscriptionsResponse {
        const message = { activeSubscriptions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListActiveSubscriptionsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListActiveSubscriptionsResponse): ListActiveSubscriptionsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated plan.v1.ActiveSubscriptionWithPayment active_subscriptions */ 1:
                    message.activeSubscriptions.push(ActiveSubscriptionWithPayment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListActiveSubscriptionsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated plan.v1.ActiveSubscriptionWithPayment active_subscriptions = 1; */
        for (let i = 0; i < message.activeSubscriptions.length; i++)
            ActiveSubscriptionWithPayment.internalBinaryWrite(message.activeSubscriptions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.ListActiveSubscriptionsResponse
 */
export const ListActiveSubscriptionsResponse = new ListActiveSubscriptionsResponse$Type();
/**
 * @generated ServiceType for protobuf service plan.v1.PlanAPI
 */
export const PlanAPI = new ServiceType("plan.v1.PlanAPI", [
    { name: "CreatePlan", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_ADMIN"] } }, I: CreatePlanRequest, O: CreatePlanResponse },
    { name: "UpdatePlan", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_ADMIN"] } }, I: UpdatePlanRequest, O: UpdatePlanResponse },
    { name: "DeletePlan", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_ADMIN"] } }, I: DeletePlanRequest, O: DeletePlanResponse },
    { name: "ListPlans", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: ListPlansRequest, O: ListPlansResponse },
    { name: "ListAllPlans", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_ADMIN"] } }, I: ListAllPlansRequest, O: ListAllPlansResponse },
    { name: "GetPlan", options: {}, I: GetPlanRequest, O: GetPlanResponse },
    { name: "GetPlanFinalAmount", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: GetPlanFinalAmountRequest, O: GetPlanFinalAmountResponse },
    { name: "PurchasePlan", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: PurchasePlanRequest, O: PurchasePlanResponse },
    { name: "ProcessPurchasePlan", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: ProcessPurchasePlanRequest, O: ProcessPurchasePlanResponse },
    { name: "GetActiveSubscription", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: GetActiveSubscriptionRequest, O: GetActiveSubscriptionResponse },
    { name: "ManualPlanActivation", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_ADMIN"] } }, I: ManualPlanActivationRequest, O: ManualPlanActivationResponse },
    { name: "ListActiveSubscriptions", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_ADMIN"] } }, I: ListActiveSubscriptionsRequest, O: ListActiveSubscriptionsResponse }
], { "base.v1.service_permission": { requireAuth: true } });
