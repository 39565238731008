export const sport = process.env.REACT_APP_SPORT ?? "badminton";
export const appName = process.env.REACT_APP_NAME ?? "Reticulo";
export const googleStoreLink = process.env.REACT_APP_GOOGLE_STORE_LINK ?? "";
export const appleStoreLink = process.env.REACT_APP_APPLE_STORE_LINK ?? "";
export const domain = (
  process.env.REACT_APP_WEBSITE_DOMAIN ?? "baddyboard.reticulo.in"
)
  .replaceAll("https://", "")
  .replaceAll("http://", "")
  .replaceAll("/", "");
