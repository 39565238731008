import { ReactNode } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGlobalStateContext } from "hooks/useGlobalState";
import { AccountDeleteRequested, DisplayError, Loading } from "components";
import { useLogout } from "hooks";
import QRCode from "react-qr-code";
import {
  Avatar,
  Box,
  Button,
  chakra,
  Flex,
  HStack,
  IconButton,
  MenuButton,
  MenuItem,
  MenuList,
  Menu,
  useColorModeValue,
  useDisclosure,
  Modal,
  Center,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  MenuGroup,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Icon,
  SimpleGrid,
  GridItem,
} from "@chakra-ui/react";
import React from "react";
import {
  AiFillBell,
  AiOutlineMenu,
  AiOutlinePlusCircle,
  AiOutlineQrcode,
} from "react-icons/ai";
import {
  MdInsertInvitation,
  MdOutlineGroup,
  MdOutlinePrivacyTip,
  MdSpaceDashboard,
} from "react-icons/md";
import { FaChalkboardTeacher } from "react-icons/fa";
import { doesUserHaveAccess } from "permissions";
import { ResourceType } from "proto/base/v1/resource";
import { User } from "proto/base/v1/user";
import { Subscription } from "proto/plan/v1/plan";
import { protoUserName } from "helpers";
import { CgProfile } from "react-icons/cg";
import { IoLogOutOutline } from "react-icons/io5";
import { appName } from "env";
import { BsShareFill } from "react-icons/bs";
import { RWebShare } from "react-web-share";
import { TwirpErrorCode } from "twirp-ts";

const allLinks = [
  {
    label: "Dashboard",
    icon: <MdSpaceDashboard />,
    iconPlain: MdSpaceDashboard,
    link: "/",
    match: new RegExp(/^[/]$/g),
  },
  {
    label: "My Space",
    icon: <FaChalkboardTeacher />,
    iconPlain: FaChalkboardTeacher,
    link: "/boards",
    match: new RegExp(/[/]board/g),
  },
  {
    label: "Rooms",
    icon: <MdOutlineGroup />,
    iconPlain: MdOutlineGroup,
    link: "/rooms",
    display: doesUserHaveAccess(ResourceType.ROOM),
    match: new RegExp(/[/]room/g),
  },
  {
    label: "Shared with Me",
    icon: <MdInsertInvitation />,
    iconPlain: MdInsertInvitation,
    link: "/invited-rooms",
    display: (
      user?: User,
      hasActiveSubscription?: boolean,
      subscription?: Subscription
    ): boolean => {
      return hasActiveSubscription ?? false;
    },
    match: new RegExp(/[/]invited-room/g),
  },
  {
    label: "Privacy Policy",
    icon: <MdOutlinePrivacyTip />,
    iconPlain: MdOutlinePrivacyTip,
    link: "https://reticulo.in/privacy",
    match: new RegExp(/^[/]$/g),
    external: true,
  },
];

export const MainLayout = ({ children }: { children: ReactNode }) => {
  const bg = useColorModeValue("gray.50", "gray.800");
  const sidebar = useDisclosure();
  const { loaded, data, error, isError, updateLastNotificationDate } =
    useGlobalStateContext();
  const location = useLocation();
  const onLogout = useLogout();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  if (!loaded) {
    return <Loading />;
  }
  if (isError) {
    if (error?.code === TwirpErrorCode.Unavailable) {
      return <AccountDeleteRequested error={error} />;
    }
    return <DisplayError error={error} />;
  }

  const NavItem = (props: any) => {
    const { icon, children, ...rest } = props;
    return (
      <Flex
        align="center"
        px="4"
        pl="4"
        py="3"
        cursor="pointer"
        color="inherit"
        _dark={{
          color: "gray.400",
        }}
        _hover={{
          bg: "gray.100",
          _dark: {
            bg: "gray.900",
          },
          color: "gray.900",
        }}
        role="group"
        fontWeight="semibold"
        transition=".15s ease"
        {...rest}
      >
        {icon && <Icon mx="2" fontSize="lg" as={icon} color="brand.500" />}
        {children}
      </Flex>
    );
  };

  const SidebarContent = (props: any) => (
    <Box
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      zIndex="sticky"
      h="full"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg="white"
      _dark={{
        bg: "gray.800",
      }}
      border
      color="inherit"
      borderRightWidth="1px"
      w="60"
      {...props}
    >
      <Flex px="4" py="5" align="center" justifyContent={""}>
        <Text
          fontSize="2xl"
          ml="2"
          color="brand.500"
          _dark={{
            color: "white",
          }}
          fontWeight="semibold"
        >
          Reticulo {appName}
        </Text>
        {/* <IconButton
          ml={4}
          size="md"
          icon={<AiOutlineClose />}
          aria-label="Open Menu"
          onClick={isOpen ? onClose : onOpen}
        /> */}
      </Flex>
      <Flex
        direction="column"
        as="nav"
        fontSize="sm"
        color="gray.600"
        aria-label="Main Navigation"
      >
        {allLinks
          .filter(
            (l) =>
              !(
                !data ||
                (l.display &&
                  !l.display(
                    data.user,
                    data.hasActiveSubscription,
                    data.subscription
                  ))
              )
          )
          .map((l) => {
            if (l.external === true) {
              return (
                <NavItem
                  icon={l.iconPlain}
                  key={l.link}
                  onClick={() => {
                    window.open(l.link);
                  }}
                >
                  <Text textColor={"brand.500"} fontSize="lg">
                    {l.label}
                  </Text>
                </NavItem>
              );
            }
            return (
              <Link to={l.link} onClick={sidebar.onClose} key={l.link}>
                <NavItem icon={l.iconPlain}>
                  <Text textColor={"brand.500"} fontSize="lg">
                    {l.label}
                  </Text>
                </NavItem>
              </Link>
            );
          })}
      </Flex>
    </Box>
  );

  return (
    <Box bg={bg} overflow="hidden" minH="100vh">
      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement="left"
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent w="full" borderRight="none" />
        </DrawerContent>
      </Drawer>
      <React.Fragment>
        <chakra.header
          bg={"brand.500"}
          w="full"
          px={{
            base: 2,
            sm: 4,
          }}
          roundedBottom={"lg"}
          display={!location.pathname.includes("/board/") ? "block" : "none"}
        >
          <Flex
            alignItems="center"
            // justifyContent="space-between"
            mx="auto"
            h={["10vh", "7vh", "7vh"]}
          >
            <HStack display="flex" spacing={3} zIndex={500}>
              <Box
                display={{
                  base: "inline-flex",
                  lg: "none",
                }}
              >
                <IconButton
                  display={{
                    base: "flex",
                    lg: "none",
                  }}
                  aria-label="Open menu"
                  fontSize="20px"
                  color="white"
                  _dark={{
                    color: "inherit",
                  }}
                  _hover={{
                    bgColor: "",
                  }}
                  variant="filled"
                  icon={<AiOutlineMenu />}
                  onClick={sidebar.onOpen}
                />
              </Box>
              <Box display={{ base: "none", lg: "block" }}>
                <Center fontSize={["2xl", "3xl", "3xl"]} color="white">
                  {appName}
                </Center>
              </Box>

              <HStack
                display={{
                  base: "none",
                  lg: "inline-flex",
                }}
              >
                {allLinks
                  .filter(
                    (l) =>
                      !(
                        !data ||
                        (l.display &&
                          !l.display(
                            data.user,
                            data.hasActiveSubscription,
                            data.subscription
                          ))
                      )
                  )
                  .map((l) => {
                    return (
                      <Button
                        key={l.link}
                        variant={l.link === location.pathname ? "outline" : ""}
                        colorScheme={
                          l.link === location.pathname ? "brand" : "tranperant"
                        }
                        _hover={{
                          bgColor: "",
                        }}
                        leftIcon={l.icon}
                        size="md"
                        color={"white"}
                        px={4}
                        onClick={() => navigate(l.link)}
                      >
                        {l.label}
                      </Button>
                    );
                  })}
              </HStack>
            </HStack>
            <HStack display={{ lg: "none" }}>
              <Text fontSize={["2xl", "3xl", "3xl"]} color="white">
                {appName}
              </Text>
            </HStack>
            <HStack
              w="100%"
              justifyContent="flex-end"
              spacing={1}
              // display={sidebar.isOpen ? "none" : "flex"}
              alignItems="flex-end"
            >
              <SimpleGrid
                columns={!location.pathname.includes("/board/") ? 4 : 3}
              >
                <GridItem colSpan={1}>
                  <RWebShare
                    data={{
                      text: appName,
                      url: window.location.toString(),
                      title: appName,
                    }}
                    onClick={() => console.log("shared successfully!")}
                  >
                    <Button rounded={"3xl"}>
                      <chakra.span pos="relative" display="inline-block">
                        <BsShareFill size={"20"} />
                      </chakra.span>
                    </Button>
                  </RWebShare>
                </GridItem>
                {!location.pathname.includes("/board/") && (
                  <GridItem colSpan={1}>
                    <Button
                      rounded={"3xl"}
                      onClick={() => {
                        navigate("/new-board");
                      }}
                    >
                      <chakra.span pos="relative" display="inline-block">
                        <AiOutlinePlusCircle size={"24"} />
                      </chakra.span>
                    </Button>
                  </GridItem>
                )}
                <GridItem colSpan={1}>
                  <Button
                    rounded={"3xl"}
                    onClick={() => {
                      navigate("/notifications");
                      updateLastNotificationDate();
                    }}
                  >
                    <chakra.span pos="relative" display="inline-block">
                      <AiFillBell size={"20"} />
                      <chakra.span
                        hidden={data?.notificationsCount === 0}
                        pos="absolute"
                        top="-1px"
                        right="-1px"
                        px={2}
                        py={1}
                        fontSize="xs"
                        fontWeight="bold"
                        lineHeight="none"
                        color="red.100"
                        transform="translate(50%,-50%)"
                        bg="red.600"
                        rounded="full"
                      >
                        {data?.notificationsCount}
                      </chakra.span>
                    </chakra.span>
                  </Button>
                  {/* <IconButton
                    aria-label="label"
                    isRound
                    size={"lg"}
                    icon={
                      <>
                        <AiFillBell />
                        <chakra.span
                          hidden={data?.notificationsCount === 0}
                          pos="absolute"
                          top="-1px"
                          right="-1px"
                          px={2}
                          py={1}
                          fontSize="xs"
                          fontWeight="bold"
                          lineHeight="none"
                          color="red.100"
                          transform="translate(50%,-50%)"
                          bg="red.600"
                          rounded="full"
                        >
                          {data?.notificationsCount}
                        </chakra.span>
                      </>
                    }
                  /> */}
                </GridItem>
                <GridItem colSpan={1}>
                  <Center h="full">
                    <Menu>
                      <MenuButton
                        border="solid"
                        borderColor="brand.600"
                        as={IconButton}
                        rounded={"full"}
                        variant={"link"}
                        cursor={"pointer"}
                        minW={0}
                        defaultValue={-1}
                      >
                        <Avatar bg="brand.900" size="xs" />
                      </MenuButton>
                      <MenuList>
                        <MenuGroup
                          title={`Welcome ${protoUserName(data?.user)}!`}
                        >
                          <MenuItem
                            onClick={() => navigate("/profile")}
                            icon={<CgProfile />}
                          >
                            My Profile
                          </MenuItem>
                          <MenuItem onClick={onOpen} icon={<AiOutlineQrcode />}>
                            Show my QR
                          </MenuItem>
                          <MenuItem
                            onClick={onLogout}
                            icon={<IoLogOutOutline />}
                          >
                            Logout
                          </MenuItem>
                        </MenuGroup>
                      </MenuList>
                    </Menu>
                  </Center>
                </GridItem>
              </SimpleGrid>
            </HStack>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent shadow={"lg"} maxW="80vw">
                <ModalHeader>Your QR Code</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Center pb={12}>
                    <QRCode value={JSON.stringify(data?.user ?? "{}")} />
                  </Center>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>
        </chakra.header>
      </React.Fragment>
      {children}
    </Box>
  );
};
