// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "base/v1/sort.proto" (package "base.v1", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message base.v1.Page
 */
export interface Page {
    /**
     * which page to list
     *
     * @generated from protobuf field: int32 no = 1;
     */
    no: number;
    /**
     * size of the page
     *
     * @generated from protobuf field: int32 size = 2;
     */
    size: number;
    /**
     * sort direction
     *
     * @generated from protobuf field: base.v1.SortDirection sort_direction = 3;
     */
    sortDirection: SortDirection;
}
/**
 * @generated from protobuf enum base.v1.SortDirection
 */
export enum SortDirection {
    /**
     * @generated from protobuf enum value: SORT_DIRECTION_INVALID = 0;
     */
    INVALID = 0,
    /**
     * @generated from protobuf enum value: SORT_DIRECTION_ASCENDING = 1;
     */
    ASCENDING = 1,
    /**
     * @generated from protobuf enum value: SORT_DIRECTION_DESCENDING = 2;
     */
    DESCENDING = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class Page$Type extends MessageType<Page> {
    constructor() {
        super("base.v1.Page", [
            { no: 1, name: "no", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "sort_direction", kind: "enum", T: () => ["base.v1.SortDirection", SortDirection, "SORT_DIRECTION_"] }
        ]);
    }
    create(value?: PartialMessage<Page>): Page {
        const message = { no: 0, size: 0, sortDirection: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Page>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Page): Page {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 no */ 1:
                    message.no = reader.int32();
                    break;
                case /* int32 size */ 2:
                    message.size = reader.int32();
                    break;
                case /* base.v1.SortDirection sort_direction */ 3:
                    message.sortDirection = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Page, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 no = 1; */
        if (message.no !== 0)
            writer.tag(1, WireType.Varint).int32(message.no);
        /* int32 size = 2; */
        if (message.size !== 0)
            writer.tag(2, WireType.Varint).int32(message.size);
        /* base.v1.SortDirection sort_direction = 3; */
        if (message.sortDirection !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortDirection);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message base.v1.Page
 */
export const Page = new Page$Type();
