import { IconProps, Image, useColorModeValue } from "@chakra-ui/react";

export const Logo = (props: IconProps) => {
  return (
    <Image
      src="/favicon.ico"
      alt="logo"
      bg={useColorModeValue("white", "gray.900")}
      rounded="xl"
      p={1}
      h={8}
    />
  );
};
