import {
  CreateRoomRequest,
  CreateRoomResponse,
  ListRoomsRequest,
  ListRoomsResponse,
  ListInvitedRoomsRequest,
  ListInvitedRoomsResponse,
  GetRoomRequest,
  GetRoomResponse,
  AddRoomUserRequest,
  AddRoomUserResponse,
  DeleteRoomUserRequest,
  DeleteRoomUserResponse,
  DeleteRoomRequest,
  DeleteRoomResponse,
  UpdateUserRoomPermissionRequest,
  UpdateUserRoomPermissionResponse,
} from "./room_api";

//==================================//
//          Client Code             //
//==================================//

interface Rpc {
  request(
    service: string,
    method: string,
    contentType: "application/json" | "application/protobuf",
    data: object | Uint8Array
  ): Promise<object | Uint8Array>;
}

export interface RoomAPIClient {
  CreateRoom(request: CreateRoomRequest): Promise<CreateRoomResponse>;
  ListRooms(request: ListRoomsRequest): Promise<ListRoomsResponse>;
  ListInvitedRooms(
    request: ListInvitedRoomsRequest
  ): Promise<ListInvitedRoomsResponse>;
  GetRoom(request: GetRoomRequest): Promise<GetRoomResponse>;
  AddRoomUser(request: AddRoomUserRequest): Promise<AddRoomUserResponse>;
  DeleteRoomUser(
    request: DeleteRoomUserRequest
  ): Promise<DeleteRoomUserResponse>;
  DeleteRoom(request: DeleteRoomRequest): Promise<DeleteRoomResponse>;
  UpdateUserRoomPermission(
    request: UpdateUserRoomPermissionRequest
  ): Promise<UpdateUserRoomPermissionResponse>;
}

export class RoomAPIClientJSON implements RoomAPIClient {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreateRoom.bind(this);
    this.ListRooms.bind(this);
    this.ListInvitedRooms.bind(this);
    this.GetRoom.bind(this);
    this.AddRoomUser.bind(this);
    this.DeleteRoomUser.bind(this);
    this.DeleteRoom.bind(this);
    this.UpdateUserRoomPermission.bind(this);
  }
  CreateRoom(request: CreateRoomRequest): Promise<CreateRoomResponse> {
    const data = CreateRoomRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "room.v1.RoomAPI",
      "CreateRoom",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      CreateRoomResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  ListRooms(request: ListRoomsRequest): Promise<ListRoomsResponse> {
    const data = ListRoomsRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "room.v1.RoomAPI",
      "ListRooms",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      ListRoomsResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  ListInvitedRooms(
    request: ListInvitedRoomsRequest
  ): Promise<ListInvitedRoomsResponse> {
    const data = ListInvitedRoomsRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "room.v1.RoomAPI",
      "ListInvitedRooms",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      ListInvitedRoomsResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }

  GetRoom(request: GetRoomRequest): Promise<GetRoomResponse> {
    const data = GetRoomRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "room.v1.RoomAPI",
      "GetRoom",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      GetRoomResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  AddRoomUser(request: AddRoomUserRequest): Promise<AddRoomUserResponse> {
    const data = AddRoomUserRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "room.v1.RoomAPI",
      "AddRoomUser",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      AddRoomUserResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  DeleteRoomUser(
    request: DeleteRoomUserRequest
  ): Promise<DeleteRoomUserResponse> {
    const data = DeleteRoomUserRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "room.v1.RoomAPI",
      "DeleteRoomUser",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      DeleteRoomUserResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }

  DeleteRoom(request: DeleteRoomRequest): Promise<DeleteRoomResponse> {
    const data = DeleteRoomRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "room.v1.RoomAPI",
      "DeleteRoom",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      DeleteRoomResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  UpdateUserRoomPermission(
    request: UpdateUserRoomPermissionRequest
  ): Promise<UpdateUserRoomPermissionResponse> {
    const data = UpdateUserRoomPermissionRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "room.v1.RoomAPI",
      "UpdateUserRoomPermission",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      UpdateUserRoomPermissionResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }
}

export class RoomAPIClientProtobuf implements RoomAPIClient {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreateRoom.bind(this);
    this.ListRooms.bind(this);
    this.ListInvitedRooms.bind(this);
    this.GetRoom.bind(this);
    this.AddRoomUser.bind(this);
    this.DeleteRoomUser.bind(this);
    this.DeleteRoom.bind(this);
    this.UpdateUserRoomPermission.bind(this);
  }
  CreateRoom(request: CreateRoomRequest): Promise<CreateRoomResponse> {
    const data = CreateRoomRequest.toBinary(request);
    const promise = this.rpc.request(
      "room.v1.RoomAPI",
      "CreateRoom",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      CreateRoomResponse.fromBinary(data as Uint8Array)
    );
  }

  ListRooms(request: ListRoomsRequest): Promise<ListRoomsResponse> {
    const data = ListRoomsRequest.toBinary(request);
    const promise = this.rpc.request(
      "room.v1.RoomAPI",
      "ListRooms",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      ListRoomsResponse.fromBinary(data as Uint8Array)
    );
  }

  ListInvitedRooms(
    request: ListInvitedRoomsRequest
  ): Promise<ListInvitedRoomsResponse> {
    const data = ListInvitedRoomsRequest.toBinary(request);
    const promise = this.rpc.request(
      "room.v1.RoomAPI",
      "ListInvitedRooms",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      ListInvitedRoomsResponse.fromBinary(data as Uint8Array)
    );
  }

  GetRoom(request: GetRoomRequest): Promise<GetRoomResponse> {
    const data = GetRoomRequest.toBinary(request);
    const promise = this.rpc.request(
      "room.v1.RoomAPI",
      "GetRoom",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      GetRoomResponse.fromBinary(data as Uint8Array)
    );
  }

  AddRoomUser(request: AddRoomUserRequest): Promise<AddRoomUserResponse> {
    const data = AddRoomUserRequest.toBinary(request);
    const promise = this.rpc.request(
      "room.v1.RoomAPI",
      "AddRoomUser",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      AddRoomUserResponse.fromBinary(data as Uint8Array)
    );
  }

  DeleteRoomUser(
    request: DeleteRoomUserRequest
  ): Promise<DeleteRoomUserResponse> {
    const data = DeleteRoomUserRequest.toBinary(request);
    const promise = this.rpc.request(
      "room.v1.RoomAPI",
      "DeleteRoomUser",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      DeleteRoomUserResponse.fromBinary(data as Uint8Array)
    );
  }

  DeleteRoom(request: DeleteRoomRequest): Promise<DeleteRoomResponse> {
    const data = DeleteRoomRequest.toBinary(request);
    const promise = this.rpc.request(
      "room.v1.RoomAPI",
      "DeleteRoom",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      DeleteRoomResponse.fromBinary(data as Uint8Array)
    );
  }

  UpdateUserRoomPermission(
    request: UpdateUserRoomPermissionRequest
  ): Promise<UpdateUserRoomPermissionResponse> {
    const data = UpdateUserRoomPermissionRequest.toBinary(request);
    const promise = this.rpc.request(
      "room.v1.RoomAPI",
      "UpdateUserRoomPermission",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      UpdateUserRoomPermissionResponse.fromBinary(data as Uint8Array)
    );
  }
}
