// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "notification/v1/notification_api.proto" (package "notification.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../google/protobuf/timestamp";
import { Notification } from "./notification";
import { SortDirection } from "../../base/v1/sort";
/**
 * @generated from protobuf message notification.v1.ListNotificationsRequest
 */
export interface ListNotificationsRequest {
    /**
     * which page to list
     *
     * @generated from protobuf field: int32 page = 1;
     */
    page: number;
    /**
     * size of the page
     *
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
    /**
     * sort direction
     *
     * @generated from protobuf field: base.v1.SortDirection sort_direction = 3;
     */
    sortDirection: SortDirection;
}
/**
 * @generated from protobuf message notification.v1.ListNotificationsResponse
 */
export interface ListNotificationsResponse {
    /**
     * @generated from protobuf field: repeated notification.v1.Notification notifications = 1;
     */
    notifications: Notification[];
}
/**
 * @generated from protobuf message notification.v1.GetNotificationsCountRequest
 */
export interface GetNotificationsCountRequest {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_on_after = 1;
     */
    createdOnAfter?: Timestamp;
}
/**
 * @generated from protobuf message notification.v1.GetNotificationsCountResponse
 */
export interface GetNotificationsCountResponse {
    /**
     * @generated from protobuf field: int32 count = 1;
     */
    count: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp last_notification_date = 2;
     */
    lastNotificationDate?: Timestamp;
}
// @generated message type with reflection information, may provide speed optimized methods
class ListNotificationsRequest$Type extends MessageType<ListNotificationsRequest> {
    constructor() {
        super("notification.v1.ListNotificationsRequest", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "sort_direction", kind: "enum", T: () => ["base.v1.SortDirection", SortDirection, "SORT_DIRECTION_"] }
        ]);
    }
    create(value?: PartialMessage<ListNotificationsRequest>): ListNotificationsRequest {
        const message = { page: 0, pageSize: 0, sortDirection: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListNotificationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListNotificationsRequest): ListNotificationsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 1:
                    message.page = reader.int32();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* base.v1.SortDirection sort_direction */ 3:
                    message.sortDirection = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListNotificationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int32(message.page);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* base.v1.SortDirection sort_direction = 3; */
        if (message.sortDirection !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortDirection);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notification.v1.ListNotificationsRequest
 */
export const ListNotificationsRequest = new ListNotificationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNotificationsResponse$Type extends MessageType<ListNotificationsResponse> {
    constructor() {
        super("notification.v1.ListNotificationsResponse", [
            { no: 1, name: "notifications", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Notification }
        ]);
    }
    create(value?: PartialMessage<ListNotificationsResponse>): ListNotificationsResponse {
        const message = { notifications: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListNotificationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListNotificationsResponse): ListNotificationsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated notification.v1.Notification notifications */ 1:
                    message.notifications.push(Notification.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListNotificationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated notification.v1.Notification notifications = 1; */
        for (let i = 0; i < message.notifications.length; i++)
            Notification.internalBinaryWrite(message.notifications[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notification.v1.ListNotificationsResponse
 */
export const ListNotificationsResponse = new ListNotificationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNotificationsCountRequest$Type extends MessageType<GetNotificationsCountRequest> {
    constructor() {
        super("notification.v1.GetNotificationsCountRequest", [
            { no: 1, name: "created_on_after", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetNotificationsCountRequest>): GetNotificationsCountRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNotificationsCountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNotificationsCountRequest): GetNotificationsCountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp created_on_after */ 1:
                    message.createdOnAfter = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdOnAfter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNotificationsCountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp created_on_after = 1; */
        if (message.createdOnAfter)
            Timestamp.internalBinaryWrite(message.createdOnAfter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notification.v1.GetNotificationsCountRequest
 */
export const GetNotificationsCountRequest = new GetNotificationsCountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNotificationsCountResponse$Type extends MessageType<GetNotificationsCountResponse> {
    constructor() {
        super("notification.v1.GetNotificationsCountResponse", [
            { no: 1, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "last_notification_date", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetNotificationsCountResponse>): GetNotificationsCountResponse {
        const message = { count: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNotificationsCountResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNotificationsCountResponse): GetNotificationsCountResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 count */ 1:
                    message.count = reader.int32();
                    break;
                case /* google.protobuf.Timestamp last_notification_date */ 2:
                    message.lastNotificationDate = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.lastNotificationDate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNotificationsCountResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 count = 1; */
        if (message.count !== 0)
            writer.tag(1, WireType.Varint).int32(message.count);
        /* google.protobuf.Timestamp last_notification_date = 2; */
        if (message.lastNotificationDate)
            Timestamp.internalBinaryWrite(message.lastNotificationDate, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notification.v1.GetNotificationsCountResponse
 */
export const GetNotificationsCountResponse = new GetNotificationsCountResponse$Type();
/**
 * @generated ServiceType for protobuf service notification.v1.NotificationAPI
 */
export const NotificationAPI = new ServiceType("notification.v1.NotificationAPI", [
    { name: "ListNotifications", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: ListNotificationsRequest, O: ListNotificationsResponse },
    { name: "GetNotificationsCount", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: GetNotificationsCountRequest, O: GetNotificationsCountResponse }
], { "base.v1.service_permission": { requireAuth: true } });
