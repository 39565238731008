// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "room/v1/room_api.proto" (package "room.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { AccessType } from "../../base/v1/access";
import { Room } from "./room";
/**
 * @generated from protobuf message room.v1.CreateRoomRequest
 */
export interface CreateRoomRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string color = 2;
     */
    color: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
}
/**
 * @generated from protobuf message room.v1.CreateRoomResponse
 */
export interface CreateRoomResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message room.v1.ListRoomsRequest
 */
export interface ListRoomsRequest {
}
/**
 * @generated from protobuf message room.v1.ListRoomsResponse
 */
export interface ListRoomsResponse {
    /**
     * @generated from protobuf field: repeated room.v1.Room rooms = 1;
     */
    rooms: Room[];
}
/**
 * @generated from protobuf message room.v1.ListInvitedRoomsRequest
 */
export interface ListInvitedRoomsRequest {
}
/**
 * @generated from protobuf message room.v1.ListInvitedRoomsResponse
 */
export interface ListInvitedRoomsResponse {
    /**
     * @generated from protobuf field: repeated room.v1.Room rooms = 1;
     */
    rooms: Room[];
}
/**
 * @generated from protobuf message room.v1.GetRoomRequest
 */
export interface GetRoomRequest {
    /**
     * @generated from protobuf field: string room_id = 1;
     */
    roomId: string;
}
/**
 * @generated from protobuf message room.v1.GetRoomResponse
 */
export interface GetRoomResponse {
    /**
     * @generated from protobuf field: room.v1.Room room = 1;
     */
    room?: Room;
}
/**
 * @generated from protobuf message room.v1.AddRoomUserRequest
 */
export interface AddRoomUserRequest {
    /**
     * @generated from protobuf field: string room_id = 1;
     */
    roomId: string;
    /**
     * @generated from protobuf field: string user_id = 2;
     */
    userId: string;
    /**
     * @generated from protobuf field: base.v1.AccessType access_type = 3;
     */
    accessType: AccessType;
}
/**
 * @generated from protobuf message room.v1.AddRoomUserResponse
 */
export interface AddRoomUserResponse {
}
/**
 * @generated from protobuf message room.v1.DeleteRoomUserRequest
 */
export interface DeleteRoomUserRequest {
    /**
     * @generated from protobuf field: string room_id = 1;
     */
    roomId: string;
    /**
     * @generated from protobuf field: string user_id = 2;
     */
    userId: string;
}
/**
 * @generated from protobuf message room.v1.DeleteRoomUserResponse
 */
export interface DeleteRoomUserResponse {
}
/**
 * @generated from protobuf message room.v1.DeleteRoomRequest
 */
export interface DeleteRoomRequest {
    /**
     * @generated from protobuf field: string room_id = 1;
     */
    roomId: string;
}
/**
 * @generated from protobuf message room.v1.DeleteRoomResponse
 */
export interface DeleteRoomResponse {
}
/**
 * @generated from protobuf message room.v1.UpdateUserRoomPermissionRequest
 */
export interface UpdateUserRoomPermissionRequest {
    /**
     * @generated from protobuf field: string room_id = 1;
     */
    roomId: string;
    /**
     * @generated from protobuf field: string user_id = 2;
     */
    userId: string;
    /**
     * @generated from protobuf field: base.v1.AccessType access_type = 3;
     */
    accessType: AccessType;
}
/**
 * @generated from protobuf message room.v1.UpdateUserRoomPermissionResponse
 */
export interface UpdateUserRoomPermissionResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class CreateRoomRequest$Type extends MessageType<CreateRoomRequest> {
    constructor() {
        super("room.v1.CreateRoomRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "color", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateRoomRequest>): CreateRoomRequest {
        const message = { name: "", color: "", description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateRoomRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateRoomRequest): CreateRoomRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string color */ 2:
                    message.color = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateRoomRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string color = 2; */
        if (message.color !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.color);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.CreateRoomRequest
 */
export const CreateRoomRequest = new CreateRoomRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRoomResponse$Type extends MessageType<CreateRoomResponse> {
    constructor() {
        super("room.v1.CreateRoomResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateRoomResponse>): CreateRoomResponse {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateRoomResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateRoomResponse): CreateRoomResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateRoomResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.CreateRoomResponse
 */
export const CreateRoomResponse = new CreateRoomResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRoomsRequest$Type extends MessageType<ListRoomsRequest> {
    constructor() {
        super("room.v1.ListRoomsRequest", []);
    }
    create(value?: PartialMessage<ListRoomsRequest>): ListRoomsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListRoomsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRoomsRequest): ListRoomsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListRoomsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.ListRoomsRequest
 */
export const ListRoomsRequest = new ListRoomsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRoomsResponse$Type extends MessageType<ListRoomsResponse> {
    constructor() {
        super("room.v1.ListRoomsResponse", [
            { no: 1, name: "rooms", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Room }
        ]);
    }
    create(value?: PartialMessage<ListRoomsResponse>): ListRoomsResponse {
        const message = { rooms: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListRoomsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRoomsResponse): ListRoomsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated room.v1.Room rooms */ 1:
                    message.rooms.push(Room.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListRoomsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated room.v1.Room rooms = 1; */
        for (let i = 0; i < message.rooms.length; i++)
            Room.internalBinaryWrite(message.rooms[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.ListRoomsResponse
 */
export const ListRoomsResponse = new ListRoomsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListInvitedRoomsRequest$Type extends MessageType<ListInvitedRoomsRequest> {
    constructor() {
        super("room.v1.ListInvitedRoomsRequest", []);
    }
    create(value?: PartialMessage<ListInvitedRoomsRequest>): ListInvitedRoomsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListInvitedRoomsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListInvitedRoomsRequest): ListInvitedRoomsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListInvitedRoomsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.ListInvitedRoomsRequest
 */
export const ListInvitedRoomsRequest = new ListInvitedRoomsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListInvitedRoomsResponse$Type extends MessageType<ListInvitedRoomsResponse> {
    constructor() {
        super("room.v1.ListInvitedRoomsResponse", [
            { no: 1, name: "rooms", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Room }
        ]);
    }
    create(value?: PartialMessage<ListInvitedRoomsResponse>): ListInvitedRoomsResponse {
        const message = { rooms: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListInvitedRoomsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListInvitedRoomsResponse): ListInvitedRoomsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated room.v1.Room rooms */ 1:
                    message.rooms.push(Room.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListInvitedRoomsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated room.v1.Room rooms = 1; */
        for (let i = 0; i < message.rooms.length; i++)
            Room.internalBinaryWrite(message.rooms[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.ListInvitedRoomsResponse
 */
export const ListInvitedRoomsResponse = new ListInvitedRoomsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRoomRequest$Type extends MessageType<GetRoomRequest> {
    constructor() {
        super("room.v1.GetRoomRequest", [
            { no: 1, name: "room_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetRoomRequest>): GetRoomRequest {
        const message = { roomId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRoomRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRoomRequest): GetRoomRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string room_id */ 1:
                    message.roomId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRoomRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string room_id = 1; */
        if (message.roomId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.roomId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.GetRoomRequest
 */
export const GetRoomRequest = new GetRoomRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRoomResponse$Type extends MessageType<GetRoomResponse> {
    constructor() {
        super("room.v1.GetRoomResponse", [
            { no: 1, name: "room", kind: "message", T: () => Room }
        ]);
    }
    create(value?: PartialMessage<GetRoomResponse>): GetRoomResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRoomResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRoomResponse): GetRoomResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* room.v1.Room room */ 1:
                    message.room = Room.internalBinaryRead(reader, reader.uint32(), options, message.room);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRoomResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* room.v1.Room room = 1; */
        if (message.room)
            Room.internalBinaryWrite(message.room, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.GetRoomResponse
 */
export const GetRoomResponse = new GetRoomResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddRoomUserRequest$Type extends MessageType<AddRoomUserRequest> {
    constructor() {
        super("room.v1.AddRoomUserRequest", [
            { no: 1, name: "room_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "access_type", kind: "enum", T: () => ["base.v1.AccessType", AccessType, "ACCESS_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<AddRoomUserRequest>): AddRoomUserRequest {
        const message = { roomId: "", userId: "", accessType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddRoomUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddRoomUserRequest): AddRoomUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string room_id */ 1:
                    message.roomId = reader.string();
                    break;
                case /* string user_id */ 2:
                    message.userId = reader.string();
                    break;
                case /* base.v1.AccessType access_type */ 3:
                    message.accessType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddRoomUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string room_id = 1; */
        if (message.roomId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.roomId);
        /* string user_id = 2; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        /* base.v1.AccessType access_type = 3; */
        if (message.accessType !== 0)
            writer.tag(3, WireType.Varint).int32(message.accessType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.AddRoomUserRequest
 */
export const AddRoomUserRequest = new AddRoomUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddRoomUserResponse$Type extends MessageType<AddRoomUserResponse> {
    constructor() {
        super("room.v1.AddRoomUserResponse", []);
    }
    create(value?: PartialMessage<AddRoomUserResponse>): AddRoomUserResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddRoomUserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddRoomUserResponse): AddRoomUserResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AddRoomUserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.AddRoomUserResponse
 */
export const AddRoomUserResponse = new AddRoomUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteRoomUserRequest$Type extends MessageType<DeleteRoomUserRequest> {
    constructor() {
        super("room.v1.DeleteRoomUserRequest", [
            { no: 1, name: "room_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteRoomUserRequest>): DeleteRoomUserRequest {
        const message = { roomId: "", userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteRoomUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteRoomUserRequest): DeleteRoomUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string room_id */ 1:
                    message.roomId = reader.string();
                    break;
                case /* string user_id */ 2:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteRoomUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string room_id = 1; */
        if (message.roomId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.roomId);
        /* string user_id = 2; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.DeleteRoomUserRequest
 */
export const DeleteRoomUserRequest = new DeleteRoomUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteRoomUserResponse$Type extends MessageType<DeleteRoomUserResponse> {
    constructor() {
        super("room.v1.DeleteRoomUserResponse", []);
    }
    create(value?: PartialMessage<DeleteRoomUserResponse>): DeleteRoomUserResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteRoomUserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteRoomUserResponse): DeleteRoomUserResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteRoomUserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.DeleteRoomUserResponse
 */
export const DeleteRoomUserResponse = new DeleteRoomUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteRoomRequest$Type extends MessageType<DeleteRoomRequest> {
    constructor() {
        super("room.v1.DeleteRoomRequest", [
            { no: 1, name: "room_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteRoomRequest>): DeleteRoomRequest {
        const message = { roomId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteRoomRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteRoomRequest): DeleteRoomRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string room_id */ 1:
                    message.roomId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteRoomRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string room_id = 1; */
        if (message.roomId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.roomId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.DeleteRoomRequest
 */
export const DeleteRoomRequest = new DeleteRoomRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteRoomResponse$Type extends MessageType<DeleteRoomResponse> {
    constructor() {
        super("room.v1.DeleteRoomResponse", []);
    }
    create(value?: PartialMessage<DeleteRoomResponse>): DeleteRoomResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteRoomResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteRoomResponse): DeleteRoomResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteRoomResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.DeleteRoomResponse
 */
export const DeleteRoomResponse = new DeleteRoomResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserRoomPermissionRequest$Type extends MessageType<UpdateUserRoomPermissionRequest> {
    constructor() {
        super("room.v1.UpdateUserRoomPermissionRequest", [
            { no: 1, name: "room_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "access_type", kind: "enum", T: () => ["base.v1.AccessType", AccessType, "ACCESS_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<UpdateUserRoomPermissionRequest>): UpdateUserRoomPermissionRequest {
        const message = { roomId: "", userId: "", accessType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateUserRoomPermissionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserRoomPermissionRequest): UpdateUserRoomPermissionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string room_id */ 1:
                    message.roomId = reader.string();
                    break;
                case /* string user_id */ 2:
                    message.userId = reader.string();
                    break;
                case /* base.v1.AccessType access_type */ 3:
                    message.accessType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateUserRoomPermissionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string room_id = 1; */
        if (message.roomId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.roomId);
        /* string user_id = 2; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        /* base.v1.AccessType access_type = 3; */
        if (message.accessType !== 0)
            writer.tag(3, WireType.Varint).int32(message.accessType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.UpdateUserRoomPermissionRequest
 */
export const UpdateUserRoomPermissionRequest = new UpdateUserRoomPermissionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserRoomPermissionResponse$Type extends MessageType<UpdateUserRoomPermissionResponse> {
    constructor() {
        super("room.v1.UpdateUserRoomPermissionResponse", []);
    }
    create(value?: PartialMessage<UpdateUserRoomPermissionResponse>): UpdateUserRoomPermissionResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateUserRoomPermissionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserRoomPermissionResponse): UpdateUserRoomPermissionResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateUserRoomPermissionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message room.v1.UpdateUserRoomPermissionResponse
 */
export const UpdateUserRoomPermissionResponse = new UpdateUserRoomPermissionResponse$Type();
/**
 * @generated ServiceType for protobuf service room.v1.RoomAPI
 */
export const RoomAPI = new ServiceType("room.v1.RoomAPI", [
    { name: "CreateRoom", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: CreateRoomRequest, O: CreateRoomResponse },
    { name: "ListRooms", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: ListRoomsRequest, O: ListRoomsResponse },
    { name: "ListInvitedRooms", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: ListInvitedRoomsRequest, O: ListInvitedRoomsResponse },
    { name: "GetRoom", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: GetRoomRequest, O: GetRoomResponse },
    { name: "AddRoomUser", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: AddRoomUserRequest, O: AddRoomUserResponse },
    { name: "DeleteRoomUser", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: DeleteRoomUserRequest, O: DeleteRoomUserResponse },
    { name: "DeleteRoom", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: DeleteRoomRequest, O: DeleteRoomResponse },
    { name: "UpdateUserRoomPermission", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_USER"] } }, I: UpdateUserRoomPermissionRequest, O: UpdateUserRoomPermissionResponse }
], { "base.v1.service_permission": { requireAuth: true } });
