// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "base/v1/whiteboard.proto" (package "base.v1", syntax proto3)
// tslint:disable
/**
 * @generated from protobuf enum base.v1.Preset
 */
export enum Preset {
    /**
     * @generated from protobuf enum value: PRESET_INVALID = 0;
     */
    PRESET_INVALID = 0,
    /**
     * @generated from protobuf enum value: PRESET_2D = 1;
     */
    PRESET_2D = 1,
    /**
     * @generated from protobuf enum value: PRESET_SIDE = 2;
     */
    PRESET_SIDE = 2,
    /**
     * @generated from protobuf enum value: PRESET_SIDE_FLAT = 3;
     */
    PRESET_SIDE_FLAT = 3,
    /**
     * @generated from protobuf enum value: PRESET_FRONT = 4;
     */
    PRESET_FRONT = 4,
    /**
     * @generated from protobuf enum value: PRESET_FRONT_FLAT = 5;
     */
    PRESET_FRONT_FLAT = 5,
    /**
     * @generated from protobuf enum value: PRESET_2D_AO_COURT = 6;
     */
    PRESET_2D_AO_COURT = 6,
    /**
     * @generated from protobuf enum value: PRESET_2D_ROLAND_GARROS = 7;
     */
    PRESET_2D_ROLAND_GARROS = 7,
    /**
     * @generated from protobuf enum value: PRESET_2D_US_OPEN_COURT = 8;
     */
    PRESET_2D_US_OPEN_COURT = 8,
    /**
     * @generated from protobuf enum value: PRESET_2D_WIMBLEDON = 9;
     */
    PRESET_2D_WIMBLEDON = 9,
    /**
     * @generated from protobuf enum value: PRESET_3D_BROADCAST_AO = 10;
     */
    PRESET_3D_BROADCAST_AO = 10,
    /**
     * @generated from protobuf enum value: PRESET_3D_BROADCAST_RG = 11;
     */
    PRESET_3D_BROADCAST_RG = 11,
    /**
     * @generated from protobuf enum value: PRESET_3D_BROADCAST_US = 12;
     */
    PRESET_3D_BROADCAST_US = 12,
    /**
     * @generated from protobuf enum value: PRESET_3D_BROADCAST_WI = 13;
     */
    PRESET_3D_BROADCAST_WI = 13,
    /**
     * @generated from protobuf enum value: PRESET_3D_SIDE_AO = 14;
     */
    PRESET_3D_SIDE_AO = 14,
    /**
     * @generated from protobuf enum value: PRESET_3D_SIDE_RG = 15;
     */
    PRESET_3D_SIDE_RG = 15,
    /**
     * @generated from protobuf enum value: PRESET_3D_SIDE_US = 16;
     */
    PRESET_3D_SIDE_US = 16,
    /**
     * @generated from protobuf enum value: PRESET_3D_SIDE_WI = 17;
     */
    PRESET_3D_SIDE_WI = 17
}
/**
 * @generated from protobuf enum base.v1.Orientation
 */
export enum Orientation {
    /**
     * @generated from protobuf enum value: ORIENTATION_INVALID = 0;
     */
    INVALID = 0,
    /**
     * @generated from protobuf enum value: ORIENTATION_LANDSCAPE = 1;
     */
    LANDSCAPE = 1,
    /**
     * @generated from protobuf enum value: ORIENTATION_PORTRAIT = 2;
     */
    PORTRAIT = 2,
    /**
     * @generated from protobuf enum value: ORIENTATION_NEUTRAL = 3;
     */
    NEUTRAL = 3
}
