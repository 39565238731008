import { BrowserRouter, Routes, Route } from "react-router-dom";

import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react";
import * as reactRouterDom from "react-router-dom";
import { initSupertokens } from "./supertokens";
import { Main } from "./Main";
import { QueryClient, QueryClientProvider } from "react-query";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import popoverTheme from "Theme/PopoverTheme";

initSupertokens();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      retry: false,
    },
  },
});

const App = () => {
  return (
    <ChakraProvider theme={popoverTheme}>
      <ColorModeScript />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            {getSuperTokensRoutesForReactRouterDom(reactRouterDom)}
            <Route path="*" element={<Main />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ChakraProvider>
  );
};

export default App;
