import { AggregateAPIClientJSON } from "proto/aggregate/v1/aggregate_api.twirp-client";
import { RoomAPIClientJSON } from "proto/room/v1/room_api.twirp-client";
import { PlanAPIClientJSON } from "proto/plan/v1/plan_api.twirp-client";
import { TemplateAPIClientJSON } from "proto/template/v1/template_api.twirp-client";
import { UserAPIClientJSON } from "proto/user/v1/user_api.twirp-client";
import { BoardAPIClientJSON } from "proto/whiteboard/board/v1/board_api.twirp-client";
import { AxiosRPC } from "./axiosRpc";
import { NotificationAPIClientJSON } from "proto/notification/v1/notification_api.twirp-client";

export const roomAPIClient = new RoomAPIClientJSON(AxiosRPC);
export const userAPIClient = new UserAPIClientJSON(AxiosRPC);
export const aggAPIClient = new AggregateAPIClientJSON(AxiosRPC);
export const boardAPIClient = new BoardAPIClientJSON(AxiosRPC);
export const templateAPIClient = new TemplateAPIClientJSON(AxiosRPC);
export const planAPIClient = new PlanAPIClientJSON(AxiosRPC);
export const notificationAPIClient = new NotificationAPIClientJSON(AxiosRPC);
