// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "base/v1/payment.proto" (package "base.v1", syntax proto3)
// tslint:disable
/**
 * @generated from protobuf enum base.v1.PaymentStatus
 */
export enum PaymentStatus {
    /**
     * @generated from protobuf enum value: PAYMENT_STATUS_INVALID = 0;
     */
    INVALID = 0,
    /**
     * @generated from protobuf enum value: PAYMENT_STATUS_CREATED = 1;
     */
    CREATED = 1,
    /**
     * @generated from protobuf enum value: PAYMENT_STATUS_PENDING = 2;
     */
    PENDING = 2,
    /**
     * @generated from protobuf enum value: PAYMENT_STATUS_FAILED = 3;
     */
    FAILED = 3,
    /**
     * @generated from protobuf enum value: PAYMENT_STATUS_SUCCESSFUL = 4;
     */
    SUCCESSFUL = 4
}
/**
 * @generated from protobuf enum base.v1.PaymentType
 */
export enum PaymentType {
    /**
     * @generated from protobuf enum value: PAYMENT_TYPE_INVALID = 0;
     */
    INVALID = 0,
    /**
     * @generated from protobuf enum value: PAYMENT_TYPE_RAZORPAY = 1;
     */
    RAZORPAY = 1,
    /**
     * @generated from protobuf enum value: PAYMENT_TYPE_CASH = 2;
     */
    CASH = 2,
    /**
     * @generated from protobuf enum value: PAYMENT_TYPE_MANUAL = 3;
     */
    MANUAL = 3
}
