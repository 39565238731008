// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "plan/v1/plan.proto" (package "plan.v1", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { PaymentType } from "../../base/v1/payment";
import { ResourceType } from "../../base/v1/resource";
import { Timestamp } from "../../google/protobuf/timestamp";
/**
 * @generated from protobuf message plan.v1.Plan
 */
export interface Plan {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: double price = 3;
     */
    price: number;
    /**
     * @generated from protobuf field: double actual_price = 4;
     */
    actualPrice: number;
    /**
     * @generated from protobuf field: double display_price = 5;
     */
    displayPrice: number;
    /**
     * @generated from protobuf field: int32 interval = 6;
     */
    interval: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 7;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 8;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: int32 sequence_no = 9;
     */
    sequenceNo: number;
    /**
     * @generated from protobuf field: string intended_for = 10;
     */
    intendedFor: string;
    /**
     * @generated from protobuf field: string interval_string = 11;
     */
    intervalString: string;
    /**
     * @generated from protobuf field: repeated plan.v1.Credit credits = 12;
     */
    credits: Credit[];
    /**
     * @generated from protobuf field: int32 primacy = 13;
     */
    primacy: number;
    /**
     * @generated from protobuf field: bool can_clone_boards = 14;
     */
    canCloneBoards: boolean;
}
/**
 * @generated from protobuf message plan.v1.Subscription
 */
export interface Subscription {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp active_since = 2;
     */
    activeSince?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp active_till = 3;
     */
    activeTill?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp next_renewal = 4;
     */
    nextRenewal?: Timestamp;
    /**
     * @generated from protobuf field: repeated plan.v1.Credit credits = 5;
     */
    credits: Credit[];
    /**
     * @generated from protobuf field: string current_plan_id = 6;
     */
    currentPlanId: string;
    /**
     * @generated from protobuf field: bool can_clone_boards = 7;
     */
    canCloneBoards: boolean;
}
/**
 * @generated from protobuf message plan.v1.Credit
 */
export interface Credit {
    /**
     * @generated from protobuf field: base.v1.ResourceType resource_type = 1;
     */
    resourceType: ResourceType;
    /**
     * @generated from protobuf field: int64 credits = 2;
     */
    credits: number;
    /**
     * @generated from protobuf field: int64 used_credits = 3;
     */
    usedCredits: number;
}
/**
 * @generated from protobuf message plan.v1.ActiveSubscriptionWithPayment
 */
export interface ActiveSubscriptionWithPayment {
    /**
     * @generated from protobuf field: plan.v1.Subscription subscription = 1;
     */
    subscription?: Subscription;
    /**
     * @generated from protobuf field: string user_id = 2;
     */
    userId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp payment_date = 3;
     */
    paymentDate?: Timestamp;
    /**
     * @generated from protobuf field: double payment_amount = 4;
     */
    paymentAmount: number;
    /**
     * @generated from protobuf field: base.v1.PaymentType payment_type = 5;
     */
    paymentType: PaymentType;
}
// @generated message type with reflection information, may provide speed optimized methods
class Plan$Type extends MessageType<Plan> {
    constructor() {
        super("plan.v1.Plan", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "price", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "actual_price", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "display_price", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "interval", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 8, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 9, name: "sequence_no", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "intended_for", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "interval_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "credits", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Credit },
            { no: 13, name: "primacy", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "can_clone_boards", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Plan>): Plan {
        const message = { id: "", name: "", price: 0, actualPrice: 0, displayPrice: 0, interval: 0, sequenceNo: 0, intendedFor: "", intervalString: "", credits: [], primacy: 0, canCloneBoards: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Plan>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Plan): Plan {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* double price */ 3:
                    message.price = reader.double();
                    break;
                case /* double actual_price */ 4:
                    message.actualPrice = reader.double();
                    break;
                case /* double display_price */ 5:
                    message.displayPrice = reader.double();
                    break;
                case /* int32 interval */ 6:
                    message.interval = reader.int32();
                    break;
                case /* google.protobuf.Timestamp created_at */ 7:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 8:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* int32 sequence_no */ 9:
                    message.sequenceNo = reader.int32();
                    break;
                case /* string intended_for */ 10:
                    message.intendedFor = reader.string();
                    break;
                case /* string interval_string */ 11:
                    message.intervalString = reader.string();
                    break;
                case /* repeated plan.v1.Credit credits */ 12:
                    message.credits.push(Credit.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 primacy */ 13:
                    message.primacy = reader.int32();
                    break;
                case /* bool can_clone_boards */ 14:
                    message.canCloneBoards = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Plan, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* double price = 3; */
        if (message.price !== 0)
            writer.tag(3, WireType.Bit64).double(message.price);
        /* double actual_price = 4; */
        if (message.actualPrice !== 0)
            writer.tag(4, WireType.Bit64).double(message.actualPrice);
        /* double display_price = 5; */
        if (message.displayPrice !== 0)
            writer.tag(5, WireType.Bit64).double(message.displayPrice);
        /* int32 interval = 6; */
        if (message.interval !== 0)
            writer.tag(6, WireType.Varint).int32(message.interval);
        /* google.protobuf.Timestamp created_at = 7; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 8; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* int32 sequence_no = 9; */
        if (message.sequenceNo !== 0)
            writer.tag(9, WireType.Varint).int32(message.sequenceNo);
        /* string intended_for = 10; */
        if (message.intendedFor !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.intendedFor);
        /* string interval_string = 11; */
        if (message.intervalString !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.intervalString);
        /* repeated plan.v1.Credit credits = 12; */
        for (let i = 0; i < message.credits.length; i++)
            Credit.internalBinaryWrite(message.credits[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* int32 primacy = 13; */
        if (message.primacy !== 0)
            writer.tag(13, WireType.Varint).int32(message.primacy);
        /* bool can_clone_boards = 14; */
        if (message.canCloneBoards !== false)
            writer.tag(14, WireType.Varint).bool(message.canCloneBoards);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.Plan
 */
export const Plan = new Plan$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Subscription$Type extends MessageType<Subscription> {
    constructor() {
        super("plan.v1.Subscription", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "active_since", kind: "message", T: () => Timestamp },
            { no: 3, name: "active_till", kind: "message", T: () => Timestamp },
            { no: 4, name: "next_renewal", kind: "message", T: () => Timestamp },
            { no: 5, name: "credits", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Credit },
            { no: 6, name: "current_plan_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "can_clone_boards", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Subscription>): Subscription {
        const message = { name: "", credits: [], currentPlanId: "", canCloneBoards: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Subscription>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Subscription): Subscription {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* google.protobuf.Timestamp active_since */ 2:
                    message.activeSince = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.activeSince);
                    break;
                case /* google.protobuf.Timestamp active_till */ 3:
                    message.activeTill = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.activeTill);
                    break;
                case /* google.protobuf.Timestamp next_renewal */ 4:
                    message.nextRenewal = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.nextRenewal);
                    break;
                case /* repeated plan.v1.Credit credits */ 5:
                    message.credits.push(Credit.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string current_plan_id */ 6:
                    message.currentPlanId = reader.string();
                    break;
                case /* bool can_clone_boards */ 7:
                    message.canCloneBoards = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Subscription, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* google.protobuf.Timestamp active_since = 2; */
        if (message.activeSince)
            Timestamp.internalBinaryWrite(message.activeSince, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp active_till = 3; */
        if (message.activeTill)
            Timestamp.internalBinaryWrite(message.activeTill, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp next_renewal = 4; */
        if (message.nextRenewal)
            Timestamp.internalBinaryWrite(message.nextRenewal, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated plan.v1.Credit credits = 5; */
        for (let i = 0; i < message.credits.length; i++)
            Credit.internalBinaryWrite(message.credits[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string current_plan_id = 6; */
        if (message.currentPlanId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.currentPlanId);
        /* bool can_clone_boards = 7; */
        if (message.canCloneBoards !== false)
            writer.tag(7, WireType.Varint).bool(message.canCloneBoards);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.Subscription
 */
export const Subscription = new Subscription$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Credit$Type extends MessageType<Credit> {
    constructor() {
        super("plan.v1.Credit", [
            { no: 1, name: "resource_type", kind: "enum", T: () => ["base.v1.ResourceType", ResourceType, "RESOURCE_TYPE_"] },
            { no: 2, name: "credits", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "used_credits", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Credit>): Credit {
        const message = { resourceType: 0, credits: 0, usedCredits: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Credit>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Credit): Credit {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.v1.ResourceType resource_type */ 1:
                    message.resourceType = reader.int32();
                    break;
                case /* int64 credits */ 2:
                    message.credits = reader.int64().toNumber();
                    break;
                case /* int64 used_credits */ 3:
                    message.usedCredits = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Credit, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.v1.ResourceType resource_type = 1; */
        if (message.resourceType !== 0)
            writer.tag(1, WireType.Varint).int32(message.resourceType);
        /* int64 credits = 2; */
        if (message.credits !== 0)
            writer.tag(2, WireType.Varint).int64(message.credits);
        /* int64 used_credits = 3; */
        if (message.usedCredits !== 0)
            writer.tag(3, WireType.Varint).int64(message.usedCredits);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.Credit
 */
export const Credit = new Credit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActiveSubscriptionWithPayment$Type extends MessageType<ActiveSubscriptionWithPayment> {
    constructor() {
        super("plan.v1.ActiveSubscriptionWithPayment", [
            { no: 1, name: "subscription", kind: "message", T: () => Subscription },
            { no: 2, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "payment_date", kind: "message", T: () => Timestamp },
            { no: 4, name: "payment_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "payment_type", kind: "enum", T: () => ["base.v1.PaymentType", PaymentType, "PAYMENT_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<ActiveSubscriptionWithPayment>): ActiveSubscriptionWithPayment {
        const message = { userId: "", paymentAmount: 0, paymentType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ActiveSubscriptionWithPayment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActiveSubscriptionWithPayment): ActiveSubscriptionWithPayment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* plan.v1.Subscription subscription */ 1:
                    message.subscription = Subscription.internalBinaryRead(reader, reader.uint32(), options, message.subscription);
                    break;
                case /* string user_id */ 2:
                    message.userId = reader.string();
                    break;
                case /* google.protobuf.Timestamp payment_date */ 3:
                    message.paymentDate = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.paymentDate);
                    break;
                case /* double payment_amount */ 4:
                    message.paymentAmount = reader.double();
                    break;
                case /* base.v1.PaymentType payment_type */ 5:
                    message.paymentType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ActiveSubscriptionWithPayment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* plan.v1.Subscription subscription = 1; */
        if (message.subscription)
            Subscription.internalBinaryWrite(message.subscription, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string user_id = 2; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        /* google.protobuf.Timestamp payment_date = 3; */
        if (message.paymentDate)
            Timestamp.internalBinaryWrite(message.paymentDate, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* double payment_amount = 4; */
        if (message.paymentAmount !== 0)
            writer.tag(4, WireType.Bit64).double(message.paymentAmount);
        /* base.v1.PaymentType payment_type = 5; */
        if (message.paymentType !== 0)
            writer.tag(5, WireType.Varint).int32(message.paymentType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message plan.v1.ActiveSubscriptionWithPayment
 */
export const ActiveSubscriptionWithPayment = new ActiveSubscriptionWithPayment$Type();
