import { Box, Center, Link, Stack, Text, VStack } from "@chakra-ui/react";
import { TwirpError } from "twirp-ts";

interface Props {
  size?: number;
  color?: string;
  error: TwirpError | null;
}

export const AccountDeleteRequested = ({
  size = 24,
  color = "red",
  error,
}: Props) => {
  return (
    <VStack justify="center" align="stretch" h="77vh" p={4}>
      <Box w="100%">
        <Stack>
          <Center>
            <Text
              py={2}
              style={{
                textAlign: "center",
                fontWeight: 500,
                fontSize: size,
              }}
              color={color}
            >
              <p>You have requested to delete your account.</p>
              <p>
                Your account will be forever deleted after 3 Business Days from
                the delete request date.
              </p>
              <p>
                If you have not initiated the deletion of your account please
                contact us at{" "}
                <Link href="https://reticulo.in/contact-us" color={"brand.500"}>
                  https://reticulo.in/contact-us
                </Link>{" "}
                and mention this email.
              </p>
            </Text>
          </Center>
        </Stack>
      </Box>
    </VStack>
  );
};
