// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "base/v1/role.proto" (package "base.v1", syntax proto3)
// tslint:disable
/**
 * @generated from protobuf enum base.v1.RoleType
 */
export enum RoleType {
    /**
     * @generated from protobuf enum value: ROLE_TYPE_INVALID = 0;
     */
    INVALID = 0,
    /**
     * @generated from protobuf enum value: ROLE_TYPE_USER = 1;
     */
    USER = 1,
    /**
     * @generated from protobuf enum value: ROLE_TYPE_ADMIN = 2;
     */
    ADMIN = 2
}
