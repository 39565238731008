import {
  GetProfileRequest,
  GetProfileResponse,
  UpdateProfileRequest,
  UpdateProfileResponse,
  ListAllUsersRequest,
  ListAllUsersResponse,
  RequestAccountDeleteRequest,
  RequestAccountDeleteResponse,
} from "./user_api";

//==================================//
//          Client Code             //
//==================================//

interface Rpc {
  request(
    service: string,
    method: string,
    contentType: "application/json" | "application/protobuf",
    data: object | Uint8Array
  ): Promise<object | Uint8Array>;
}

export interface UserAPIClient {
  GetProfile(request: GetProfileRequest): Promise<GetProfileResponse>;
  UpdateProfile(request: UpdateProfileRequest): Promise<UpdateProfileResponse>;
  ListAllUsers(request: ListAllUsersRequest): Promise<ListAllUsersResponse>;
  RequestAccountDelete(
    request: RequestAccountDeleteRequest
  ): Promise<RequestAccountDeleteResponse>;
}

export class UserAPIClientJSON implements UserAPIClient {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetProfile.bind(this);
    this.UpdateProfile.bind(this);
    this.ListAllUsers.bind(this);
    this.RequestAccountDelete.bind(this);
  }
  GetProfile(request: GetProfileRequest): Promise<GetProfileResponse> {
    const data = GetProfileRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "user.v1.UserAPI",
      "GetProfile",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      GetProfileResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  UpdateProfile(request: UpdateProfileRequest): Promise<UpdateProfileResponse> {
    const data = UpdateProfileRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "user.v1.UserAPI",
      "UpdateProfile",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      UpdateProfileResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  ListAllUsers(request: ListAllUsersRequest): Promise<ListAllUsersResponse> {
    const data = ListAllUsersRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "user.v1.UserAPI",
      "ListAllUsers",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      ListAllUsersResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  RequestAccountDelete(
    request: RequestAccountDeleteRequest
  ): Promise<RequestAccountDeleteResponse> {
    const data = RequestAccountDeleteRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "user.v1.UserAPI",
      "RequestAccountDelete",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      RequestAccountDeleteResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }
}

export class UserAPIClientProtobuf implements UserAPIClient {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetProfile.bind(this);
    this.UpdateProfile.bind(this);
    this.ListAllUsers.bind(this);
    this.RequestAccountDelete.bind(this);
  }
  GetProfile(request: GetProfileRequest): Promise<GetProfileResponse> {
    const data = GetProfileRequest.toBinary(request);
    const promise = this.rpc.request(
      "user.v1.UserAPI",
      "GetProfile",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      GetProfileResponse.fromBinary(data as Uint8Array)
    );
  }

  UpdateProfile(request: UpdateProfileRequest): Promise<UpdateProfileResponse> {
    const data = UpdateProfileRequest.toBinary(request);
    const promise = this.rpc.request(
      "user.v1.UserAPI",
      "UpdateProfile",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      UpdateProfileResponse.fromBinary(data as Uint8Array)
    );
  }

  ListAllUsers(request: ListAllUsersRequest): Promise<ListAllUsersResponse> {
    const data = ListAllUsersRequest.toBinary(request);
    const promise = this.rpc.request(
      "user.v1.UserAPI",
      "ListAllUsers",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      ListAllUsersResponse.fromBinary(data as Uint8Array)
    );
  }

  RequestAccountDelete(
    request: RequestAccountDeleteRequest
  ): Promise<RequestAccountDeleteResponse> {
    const data = RequestAccountDeleteRequest.toBinary(request);
    const promise = this.rpc.request(
      "user.v1.UserAPI",
      "RequestAccountDelete",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      RequestAccountDeleteResponse.fromBinary(data as Uint8Array)
    );
  }
}
